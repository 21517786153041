import {React ,useState,useEffect, useContext} from 'react';
import { StyleSheet, Text} from 'react-native';
import Moment from 'moment-timezone';
//import moment from 'moment';
import NetworkContext from '../context/NetworkContext';

const DateStateTop = ({isright}) => {
    const {defaultinit} = useContext(NetworkContext); 
        
    const [dateState, setDateState] = useState((defaultinit.timeformat == "12")?Moment.tz(new Date(),defaultinit.timezone).format("dddd[\n]DD MMM YYYY"):Moment.tz(new Date(),defaultinit.timezone).format("dddd[\n]DD MMM YYYY"));
    const [timeState, setTimeState] = useState((defaultinit.timeformat == "12")?Moment.tz(new Date(),defaultinit.timezone).format("h:mm A"):Moment.tz(new Date(),defaultinit.timezone).format("HH:mm"));


    useEffect(() => {
        let intervalId = setInterval(() => 
        {
            setDateState( (defaultinit.timeformat == "12")?Moment.tz(new Date(),defaultinit.timezone).format("dddd[\n]DD MMM YYYY"):Moment.tz(new Date(),defaultinit.timezone).format("dddd[\n]DD MMM YYYY") );
            setTimeState( (defaultinit.timeformat == "12")?Moment.tz(new Date(),defaultinit.timezone).format("h:mm A"):Moment.tz(new Date(),defaultinit.timezone).format("HH:mm") );
        }
        , 1000);
        return(() => {
            clearInterval(intervalId)
        })        
    }, [defaultinit]);


    return <> 
        <Text style={[styles.datestate,{color: (isright)?defaultinit.textcolor_right:defaultinit.textcolor_main}]}>
          {dateState}
        </Text>
        <Text style={[styles.timestate,{color: (isright)?defaultinit.textcolor_right:defaultinit.textcolor_main}]}>
          {timeState}
        </Text>
    </>
}


const styles = StyleSheet.create({
    datestate: {
        fontSize: 22,
        padding: 4,
        fontWeight: 'bold',    
        textAlign: 'center',
        marginRight: 20,
        textAlign: 'right',
      },
      timestate: {
        fontSize: 40,
        fontWeight: 'bold',    
        textAlign: 'center',
      }
})
export default DateStateTop;