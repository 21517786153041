import {React,useContext } from 'react';
import { View, StyleSheet, Text} from 'react-native';
import NetworkContext from '../context/NetworkContext';
import { CountdownCircleTimer } from 'react-native-countdown-circle-timer';
import Moment from 'moment-timezone';

const CircleCountdown = () => {
    const {bookedinfo,defaultinit,getEventApi} = useContext(NetworkContext);

    return  <>
        <View style={{alignItems: 'center'}}>
          <Text style={[styles.bottomButtonTextLeftStyle, {color:defaultinit.textcolor_main }]}>
            {(defaultinit.timeformat == "12")?Moment.tz(bookedinfo.bookedstart,defaultinit.timezone).format('h:mmA'):Moment.tz(bookedinfo.bookedstart,defaultinit.timezone).format('HH:mm') } -  {(defaultinit.timeformat == "12")?Moment.tz(bookedinfo.bookedend,defaultinit.timezone).format('h:mmA'):Moment.tz(bookedinfo.bookedend,defaultinit.timezone).format('HH:mm') }
              {'\n'}
          </Text>
        </View>
        <View  style={{alignItems: 'center'}} >            
          <CountdownCircleTimer           
              //initialRemainingTime={ remaining }
              initialRemainingTime={bookedinfo["remaining"]}
              //initialRemainingTime={rmn}
              isPlaying
              //duration={ duration}
              duration={bookedinfo["duration"]}
              colors={['#4ec8fc', '#F7B801', '#A30000', '#A30000']}
              colorsTime={[7, 5, 2, 0]}
              size={140}
              strokeWidth={15}
              trailColor='#2c2c2c'
              onComplete={() => {
                getEventApi();
                // do your stuff here
                // repeat animation in 1.5 seconds
              }}             
            >
            {({ remainingTime }) => {
            const hours = (Math.floor(remainingTime / 3600)).toString().padStart(2,'0')
            const minutes = (Math.floor((remainingTime % 3600) / 60)).toString().padStart(2,'0')
            const seconds = (remainingTime % 60).toString().padStart(2,'0')
            return <Text style={[styles.datestate, {color:defaultinit.textcolor_main }]}>{hours}:{minutes}:{seconds}</Text>
            
          }}
          </CountdownCircleTimer>
      </View>  
  </> 
}

const styles = StyleSheet.create({
  datestate: {
    fontSize: 20,
    padding: 4,
    fontWeight: 'bold',
    justifyContent: 'flex-start',
    alignSelf:'center'    
  },
  bottomButtonTextLeftStyle: {
    paddingLeft:10,
    fontSize: 18,
  },
  bottomButtonTextRightStyle: {
    fontSize: 24,
    fontWeight: 'bold',
  },
});
export default CircleCountdown;