import {React,useRef, useEffect,useContext} from 'react';
import { View, StyleSheet, Text, TouchableOpacity,Modal,TextInput, } from 'react-native';
import { Entypo } from '@expo/vector-icons';
import NetworkContext from '../context/NetworkContext';

const EventTitle = ({titlesubmit,modalVisible,setModalVisible, title,onTitleChange,fromwho,onFromwhoChange,direction}) => {

    const {language} = useContext(NetworkContext);
    const input1Ref = useRef(null);
    const input2Ref = useRef(null);

    const focusNextInput = (nextInput) => {
      nextInput.current.focus();
    };

    return <View style={styles.defaultView}>
    <Modal
        animationType="fade"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
        setModalVisible(!modalVisible);
        }}
    >
        <View style={styles.centeredView}>
        <View style={(direction== "column")?styles.modalViewColumn:styles.modalView}>
            <Entypo name="circle-with-cross" size={24} color="red" style={{position: 'absolute', top: 10, right: 0, paddingRight:10}} onPress = { () => setModalVisible(!modalVisible)}/>
            
            <View style={styles.bgtextinput}>
                <TextInput style={styles.inputStyle} 
                    ref={input1Ref}
                    placeholder={language.enter_event_title}
                    value={title}
                    onChangeText={newTitle => onTitleChange(newTitle)} 
                    returnKeyType="next"
                    onSubmitEditing={() => focusNextInput(input2Ref)}           
                ></TextInput>
            </View>
            <View style={styles.bgtextinput}>
                <TextInput style={styles.inputStyle} 
                    ref={input2Ref}
                    placeholder={language.enter_name}
                    value={fromwho}
                    onChangeText={newFromwho => onFromwhoChange(newFromwho)}    
                    returnKeyType="done"  
                    onSubmitEditing={() =>  titlesubmit()}

                ></TextInput>
            </View>
            <View style={styles.mbutton}>
                <TouchableOpacity activeOpacity={0.95} style={styles.mbutton1} onPress = {() => {  titlesubmit() } } >
                    <Text style={styles.mbuttonText}>{language.submit}</Text>
                </TouchableOpacity>
            </View>
        </View>
        </View>
    </Modal>
</View>
}

const styles = StyleSheet.create({
  defaultView: {
    flexDirection: 'row',
  //   flex:1,
  },
  bgtextinput:{
    marginTop:10,
    marginBottom: 10,
    backgroundColor: '#F0EEEE',
    height: 50,
    borderRadius: 5,
    marginHorizontal:15,
    padding:5,
    flexDirection: 'row'
  },
  inputStyle:{
    fontSize:18,
    flex:1
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalViewColumn:{
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,    
    width: '50%'
  },
  mbutton:{
    //width: 300,
    alignItems: 'center',
  },
  mbutton1:{
  //  color: '#18e7f5',
    color: 'rgba(61, 181, 84, 1)',
    //flexDirection: 'row', 
  //  height: 60, 
  //  backgroundColor: '#18e7f5',
    backgroundColor: 'rgba(61, 181, 84, 1)',
    alignItems: 'center',
    justifyContent: 'center',
  //   marginTop: 5,
    elevation:3,
    borderRadius: 4
  },
  mbuttonText:{
    fontSize: 20,
    padding: 10,
    //color: '#2138fb',
    color: '#ffffff',
    fontWeight: 'bold',    
    textAlign: 'center',
  },
});

export default EventTitle;