import {React,useContext,useState,useEffect} from 'react';
import { View, StyleSheet, Text, Button,TouchableOpacity } from 'react-native';
//import { CountdownCircleTimer } from 'react-native-countdown-circle-timer';
import NetworkContext from '../context/NetworkContext';
import Moment from 'moment-timezone';
import FastBookButton from '../components/FastBookButton';
import DateStateTop from '../components/DateStateTop';
import HideShowAction from '../components/HideShowAction';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import Header from '../components/Header';

const MainBookedScreen = ({myscreen, onScreenChange, isextend,eventid,direction,fadeIn,fadeOut,hideright,onHideRightChange}) => {
  const {defaultinit,getEventApi,language,bookedinfo} = useContext(NetworkContext);

    
  if (!bookedinfo["remaining"] )
  {
      return null;        
  }

  return (
    <>
    <View style={styles.container}>
      <View style={[styles.top, {backgroundColor:defaultinit.bg_main_header_footer} ]}>
        <Header
          direction={direction}   
          hideright={hideright} 
        ></Header>
      </View>
      <View style={[styles.middle, {backgroundColor:defaultinit.bg_main_occupied_center}]}>
          <HideShowAction
            from={1} 
            direction={direction} 
            fadeIn={fadeIn} 
            fadeOut={fadeOut}                
            hideright={hideright} 
            onHideRightChange={onHideRightChange}
            />   
      </View>
      <View style={[styles.bottom, {backgroundColor:defaultinit.bg_main_occupied_center}]}>
        {
          (defaultinit.is_interactive_button == "1")?<FastBookButton direction={direction} onScreenChange={onScreenChange} eventid={bookedinfo.eventid}></FastBookButton>:null
        }
        <View style={{ flexDirection:'row' }}>
        <TouchableOpacity activeOpacity={0.95} style={[styles.bottomButtonRightStyle,{backgroundColor:defaultinit.bg_main_bookingbar}]} >
            <Text style={[styles.bottomButtonTextRightStyle, {color:defaultinit.textcolor_main} ]}>{language.occupied}</Text>
        </TouchableOpacity>
        {
          (defaultinit.is_interactive_button == "1")?<TouchableOpacity activeOpacity={0.95} style={[styles.bottomButtonPStyle, {backgroundColor:defaultinit.bg_main_freebar}]} onPress = { ()=> onScreenChange('ScheduleMeetingScreen') }><MaterialCommunityIcons name="plus-thick" size={24} color="white" /></TouchableOpacity>:null
        }
        </View>
      </View>
    </View>
  </>
  );

};

const styles = StyleSheet.create({
  top:{
    flex: 15,
  //  backgroundColor: '#203864',
    justifyContent: 'center'
  },
  middle:{
    flex: 65,
  //  backgroundColor: '#586a8b',
    justifyContent: 'center'
  //  justifyContent: 'flex-end'
  },
  bottom:{
    flex: 20,
  //  backgroundColor: '#586a8b',
    justifyContent: 'flex-end'
  },

  container: {
    flex: 1,
    justifyContent: 'center',
  //  alignItems: 'center',
  },  
  datestate: {
    fontSize: 20,
    padding: 4,
  //  color: 'white',
    fontWeight: 'bold',    
  //  textAlign: 'center',
  justifyContent: 'flex-start',
  alignSelf:'center'    
  },
  bottomButtonPStyle: {
    //  flexDirection: 'row',
    flex: 10,
      height: 60, 
  //    backgroundColor: '#06b457',
      alignItems: 'center',
      justifyContent: 'center',
  //    marginTop: 5,
  //    elevation:3,
        //   borderRadius: 50,
    },
    bottomButtonLeftStyle: {
      //  flexDirection: 'row',
      flex: 45,
      height: 60, 
      backgroundColor: '#ff0000',
      alignItems: 'flex-start', 
      justifyContent: 'center',
      //    marginTop: 5,
      //    elevation:3,
      //   borderRadius: 50,
    },
    bottomButtonRightStyle: {
      //  flexDirection: 'row',
      flex: 90,
      height: 60, 
  //    backgroundColor: '#ff0000',
      alignItems: 'center', 
      justifyContent: 'center',
      //    marginTop: 5,
      //    elevation:3,
      //   borderRadius: 50,
    },
    bottomButtonTextLeftStyle: {
      paddingLeft:10,
      fontSize: 18,
  //    color: '#fff',
      //fontWeight: 'bold',
    },
    bottomButtonTextRightStyle: {
    //  paddingRight:10,
      fontSize: 24,
    //  color: '#fff',      
      fontWeight: 'bold',
      //fontWeight: 'bold',
    },
});

export default MainBookedScreen;