import {React, useState, useContext, useEffect } from 'react';
import { View, Text, StyleSheet, Image , Modal} from 'react-native';
import NetworkContext from '../context/NetworkContext';
import { Entypo } from '@expo/vector-icons';
import QRCode from 'react-native-qrcode-svg';
import DateStateTop from './DateStateTop';

const Header = ({direction,hideright,iscalendar}) => {
    const {isOnline,defaultinit,getConfigApi} = useContext(NetworkContext);  
    const [modalVisible, setModalVisible] = useState(false);
    const [qrurl, setQrurl] = useState("https://cloud.infinityroom.pro/booking/"+defaultinit.deviceid);

    const showSecret = () => {
      getConfigApi();
      setModalVisible(true);
    }


    return <View style={styles.parentView}>
      <Modal
        animationType="fade"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
        setModalVisible(!modalVisible);
        }}
    >

        <View style={styles.centeredView}>
        <View style={styles.modalView}>
            <Entypo name="circle-with-cross" size={24} color="red" style={{position: 'absolute', top: 10, right: 0, paddingRight:10}} onPress = { () => setModalVisible(!modalVisible)}/>
            <Text style={styles.modalText}>Device Token</Text>
            <Text style={styles.modalText}>{defaultinit.secret}</Text>
        </View>
        </View>
    </Modal>

   
    <View style={styles.leftView}>
      <View style={{ flexDirection: 'row',justifyContent:'center', padding: 10}}>
       

        {
         (defaultinit.logo != "")?<Image  source={{    uri: defaultinit.logo  }}  style = {{ height: '100%', marginRight:10, aspectRatio: 4/3}}/>:null
        }
          <Text style={{fontSize: 38, color: '#fff',flex:1,alignSelf:'center'}} ellipsizeMode='tail' numberOfLines={1} onPress = {() => {  showSecret() } }>                
            {defaultinit.headername}
          </Text>
      </View>
          
    {isOnline ? null :<View style={{ top: 0, left:0, position: 'absolute',flexDirection:'row',justifyContent:'center'}}><Entypo name="warning" size={20} color="orange" /><Text style={{fontSize: 16, color: '#feb040'}}>Offline</Text></View>}

    </View>
    {
      (direction== "column" || hideright == false)?(iscalendar)?<View style={styles.middleView}><View style={{ flexDirection: 'row',justifyContent:'center', padding: 10}}><DateStateTop></DateStateTop></View></View>:(direction =="column")?<View style={styles.middleColumnView}><View style={{ flexDirection: 'row',justifyContent:'center', padding: 10}}><DateStateTop></DateStateTop></View></View>:null:<View style={styles.middleView}><View style={{ flexDirection: 'row',justifyContent:'center', padding: 10}}><DateStateTop></DateStateTop></View></View>
    }

    {
      (direction== "column" || hideright == false)?(iscalendar)?<View style={styles.rightView}><View style={styles.qrView}><QRCode value={qrurl} logoBackgroundColor='black' size={60} /></View></View>:null:<View style={styles.rightView}><View style={styles.qrView}><QRCode value={qrurl} logoBackgroundColor='black' size={60} /></View></View>
    }



    

</View>
}
const styles = StyleSheet.create({
  parentView: {
    flex:1,
    flexDirection: 'row',
  //  backgroundColor: 'orange',
  //  justifyContent: 'center'
  },
  left1View:{
    flex: 15,
    borderWidth:3,
    borderColor:'red'
  },
  leftView:{
    flex: 60,
  //  width: '60%',
    justifyContent: 'center',
  //  borderWidth:3,
  //  borderColor:'red'
  },
  middleView:{
      flex: 28,
    //  width: '28%',
      justifyContent: 'center',
    //  borderWidth:3,
    //  borderColor:'red'
   // backgroundColor: 'red'
    },
    middleColumnView:{
      flex: 50,
    //  width: '28%',
      justifyContent: 'center',
    //  borderWidth:3,
    //  borderColor:'red'
   // backgroundColor: 'red'
    },
  rightView:{
    flex: 8,
  //  width: '8%',
    justifyContent:'center',

  //  backgroundColor: 'yellow'
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5
  },
  modalText: {
    marginBottom: 6,
    textAlign: "center"
  },
  qrView: {
  //  marginTop: 20,
    backgroundColor: "white",
    borderWidth:8,
    borderColor: '#fff',
    position: 'absolute',
    right: 10,
    paddingRight:0,
  },
});

export default Header;