import {React} from 'react';
import { View, Text, StyleSheet, Image } from 'react-native';

const HeaderDevice = () => {

    return <> 
    <View style={{flex:1}}>
      <View style={{justifyContent: 'flex-start'}}>
        <Image
      style={{ width: 397, alignSelf: 'center', height: 63 }}
      source={require('../../assets/InfinityRoom-logo-397.png')}
      ></Image>
        </View>
    </View>
    </>

}

const styles = StyleSheet.create({
})

export default HeaderDevice;
    