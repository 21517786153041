import en_gb from '../locales/en.json';
import zh_cn from '../locales/cn.json';
import fr_ca from '../locales/fr.json';
import ms from '../locales/ms.json';
import th from '../locales/th.json';
import de from '../locales/de.json';
import hi from '../locales/hi.json';
import ja from '../locales/ja.json';
import vi from '../locales/vi.json';
import my from '../locales/my.json';
import tl_ph from '../locales/tlph.json';
import zh_tw from '../locales/zhtw.json';
import bn_bd from '../locales/bnbd.json';

const translations = {
    en_gb,
    zh_cn,
    fr_ca,
    de,
    th,
    ms,
    hi,
    ja,
    vi,
    my,
    tl_ph,
    zh_tw,
    bn_bd
  };


export default translations;