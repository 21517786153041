import {React, useEffect, useState} from 'react';
import { View, StyleSheet,Dimensions,StatusBar,Text,TouchableOpacity } from 'react-native';
import RNRestart from 'react-native-restart';

const screen = Dimensions.get("screen");

const ShowNoConnectionScreen = ({navigation}) => {    
    const [direction, setDirection] = useState(null);

    const myrestart = () => {
        RNRestart.Restart()
      //   DevSettings.reload();
      }
    
    useEffect( () => {
        if (screen.height > screen.width) //vertical
        {
          setDirection('column');
        }
        else
        {
          setDirection('row')
        } 

        Dimensions.addEventListener(
            "change",
            ({ screen }) => {
              if (screen.height > screen.width) //vertical
              {
                setDirection('column');
              }
              else
              {
                setDirection('row') 
              }              
            }
          );
    },[]);

        return <>
        {
        //  <StatusBar barStyle="light-content" backgroundColor="#000"/>
            <StatusBar hidden />
        }
        <View style={(direction== "column")?styles.defaultViewColumn:styles.defaultViewRow}>
          {              
              <><View style={styles.leftView}>
                    <Text style={styles.title}>
                        Can't connect to server...
                    </Text>  
                    <View style={styles.button}>
                        <TouchableOpacity activeOpacity={0.95} style={styles.button1} onPress = {() => myrestart()} >
                            <Text style={styles.buttonText}>TRY AGAIN</Text>
                        </TouchableOpacity>
                    </View>
              </View>
              </>
          }
    </View>
    </>
};

const styles = StyleSheet.create({

  defaultViewRow: {
    flexDirection: 'row',
    flex:1,
  },
  defaultViewColumn: {
      flexDirection: 'column',
      flex:1,
  },
  leftView: {
      flex: 40,
      backgroundColor: '#fff',
  },
  rightView: {
      flex: 60,
      backgroundColor:'#fff'
  },
  title: {
    fontSize: 25,
    padding: 15,
    color: '#000',
    fontWeight: 'bold',    
    textAlign: 'center',    
  },  
  button:{
    //width: 300,
    alignItems: 'center',
  },
  button1:{
    //color: '#2138fb',
    color: '#3db554',
    //flexDirection: 'row', 
  //  height: 60, 
    //backgroundColor: '#2138fb',
    backgroundColor: '#3db554',
    alignItems: 'center',
    justifyContent: 'center',
  //   marginTop: 5,
    elevation:3,
    borderRadius: 4
  },
  buttonText:{
    fontSize: 25,
    padding: 10,
    color: 'white',
    fontWeight: 'bold',    
    textAlign: 'center',
  },
});

export default ShowNoConnectionScreen;