import {React, useState, useContext, useEffect } from 'react';
import { View, Text, StyleSheet, Image , Modal} from 'react-native';
import NetworkContext from '../context/NetworkContext';
import QRCode from 'react-native-qrcode-svg';
import DateStateTop from './DateStateTop';

const HeaderRight = () => {
    const {defaultinit,getConfigApi} = useContext(NetworkContext);  
    const [qrurl, setQrurl] = useState("https://cloud.infinityroom.pro/booking/"+defaultinit.deviceid);


    return <View style={styles.parentView}>
   
    <View style={styles.leftView}>
      <View style={{ flexDirection: 'row',justifyContent:'center', padding: 10}}>
        <DateStateTop isright={1}></DateStateTop>
      </View>
          
    </View>

    <View style={styles.rightView}>
        <View style={styles.qrView}> 
        <QRCode value={qrurl} logoBackgroundColor='black' size={60} />          
      </View>
    </View>

</View>
}
const styles = StyleSheet.create({
  parentView: {
    flex:1,
    flexDirection: 'row',
  //  backgroundColor: 'orange',
  //  justifyContent: 'center'
  },
  left1View:{
    flex: 15,
    borderWidth:3,
    borderColor:'red'
  },
  leftView:{
  //  flex: 90,
    width: '80%',
    justifyContent: 'center',
  //  borderWidth:3,
  //  borderColor:'red'
  },
  rightView:{
  //  flex: 10,
    width: '20%',
    justifyContent:'center',

 //   backgroundColor: 'yellow'
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22
  },
  qrView: {
  //  marginTop: 20,
    backgroundColor: "white",
    borderWidth:8,
    borderColor: '#fff',
    position: 'absolute',
    right: 10,
    paddingRight:0,
  },
});

export default HeaderRight;