import { React, useContext,useState } from 'react';
import { View, StyleSheet, Text, Image, FlatList} from 'react-native';
import EventList from '../components/EventList';
import NetworkContext from '../context/NetworkContext';
import HeaderRight from '../components/HeaderRight';
import ShowPin from '../components/ShowPin';
import CustomAlert from '../components/CustomAlert';
import apibase from '../api/apibase';
import Moment from 'moment-timezone';


const DisplayEventScreen = ({myscreen, onScreenChange, isextend,direction,hideright}) => {
    const {defaultinit,language,events,getEventApi,setBookedinfo,bookedinfo} = useContext(NetworkContext);
    const [modalpinVisible, setModalPinVisible] = useState(false);
    const [qstatus, setQstatus] = useState(null);
    const [qeventid, setQeventid] = useState(null); 
    const [qiswholeday, setIswholeday] = useState(null); 

    const cancelfunc = () => {
        //    console.log("cancel pressed");
        };

    const showAction = (astatus,aeventid,iswholeday) =>{

        setQeventid(aeventid);
        setQstatus(astatus);
        setIswholeday(iswholeday);

        if (defaultinit.pin_required == "1")
        {
            setModalPinVisible(true);            
        }
        else
        { 
            quickaction();
        }     
     }


    const quickaction = () => {
        if (qstatus == "end")
        {
            const okfunc = async () => {
                if (defaultinit && ("api_token" in defaultinit) )
                {
                    setBookedinfo(null);                
                    apibase.defaults.headers.common['Authorization'] = 'Bearer '+defaultinit.api_token;
                    //const enddate = "456";
                    //const startdate = Moment.tz(event_start,'YYYY-MM-DD HH:mm:ss',defaultinit.timezone).unix(); //no need * 1000
                    let enddate = Moment.tz(new Date(),'YYYY-MM-DD HH:mm:ss',defaultinit.timezone).unix(); //no need * 1000                
        
                    const response = await apibase.put('calendar_provider/calendarevents?secret='+defaultinit.secret +'&eventid='+qeventid+'&endDate='+enddate+'&iswholeday='+qiswholeday)
                    .then(res => {                        
                        getEventApi();
                    })
                    .catch(error => {
                        setBookedinfo(bookedinfo);
                        if (error.response)
                        {
                            CustomAlert(""+error.response.data.message+"");
                        }
                        getEventApi();
                    });    
                }
            }
    
            CustomAlert(
                language.end_meeting,
                language.do_you_want_end_meeting,
                [
                  {
                    text: "Cancel",
                    onPress: cancelfunc,
                    style: "cancel"
                  },
                  { text: "OK", onPress: okfunc }
                ]
            );
    
        }
        else if (qstatus == "cancel")
        {
            const okfunc = async () => {

                if (defaultinit && ("api_token" in defaultinit) )
                {
                    setBookedinfo(null);                
                    apibase.defaults.headers.common['Authorization'] = 'Bearer '+defaultinit.api_token;
                    //const enddate = "456";
                    //const startdate = Moment.tz(event_start,'YYYY-MM-DD HH:mm:ss',defaultinit.timezone).unix(); //no need * 1000
                    
                    const response = await apibase.put('calendar_provider/calendarevents?secret='+defaultinit.secret +'&eventid='+qeventid)
                    .then(res => {
                        getEventApi();
                    })
                    .catch(error => {
                        setBookedinfo(bookedinfo);
                        if (error.response)
                        {
                            CustomAlert(""+error.response.data.message+"");
                        }
                        getEventApi();
                    });
                }
            }
    
            CustomAlert(
                language.cancel_meeting,
                language.do_you_want_cancel_meeting,
                [
                  {
                    text: "Cancel",
                    onPress: cancelfunc,
                    style: "cancel"
                  },
                  { text: "OK", onPress: okfunc }
                ]
            );
        }
        else if (qstatus == "start")
        {
            const okfunc = async () => {

                if (defaultinit && ("api_token" in defaultinit) )
                {
                    setBookedinfo(null);                
                    apibase.defaults.headers.common['Authorization'] = 'Bearer '+defaultinit.api_token;
                    //const enddate = "456";
                    //const startdate = Moment.tz(event_start,'YYYY-MM-DD HH:mm:ss',defaultinit.timezone).unix(); //no need * 1000
                    let startdate = Moment.tz(new Date(),'YYYY-MM-DD HH:mm:ss',defaultinit.timezone).unix(); //no need * 1000                
        
                    const response = await apibase.put('calendar_provider/calendarevents?secret='+defaultinit.secret +'&eventid='+qeventid+'&startDate='+startdate)
                    .then(res => {
                        getEventApi();
                    })
                    .catch(error => {
                        setBookedinfo(bookedinfo);
                        if (error.response)
                        {
                            CustomAlert(""+error.response.data.message+"");
                        }
                        getEventApi();
                    });
                }
            }
    
            CustomAlert(
                language.start_meeting,
                language.do_you_want_start_meeting,
                [
                  {
                    text: "Cancel",
                    onPress: cancelfunc,
                    style: "cancel"
                  },
                  { text: "OK", onPress: okfunc }
                ]
            );
        }

    }
    
    if (!events)
    {
        return null;        
    }

    if (events.length > 0)
    {
        return <>
        <ShowPin
            direction={direction}
            modalpinVisible={modalpinVisible}
            setModalPinVisible={setModalPinVisible}
            quickaction={quickaction}
            from={2}
        ></ShowPin>
          <View style={styles.container}>
            {
                (direction== "column")?null:<View style={[styles.top]}><HeaderRight></HeaderRight></View>
            }


            <View style={[styles.middle]}>
                <FlatList
                    data={events}
                    keyExtractor={ result => result.id}
                    renderItem={({item}) =>{
                        //return <View style={styles.groupview}><Text style={styles.listdetail}>{item.status}</Text><Text style={styles.listdetail}>{item.title}</Text></View>
                        return <EventList showAction={showAction} title={item.title} results={item.srcdata} resultid={item.id} onScreenChange={onScreenChange}></EventList>
                    }}            
                />
            </View>
          </View>
        </>


        /*
        return <View> 



            <FlatList
            data={events}
            keyExtractor={ result => result.id}
            renderItem={({item}) =>{
                //return <View style={styles.groupview}><Text style={styles.listdetail}>{item.status}</Text><Text style={styles.listdetail}>{item.title}</Text></View>
                return <EventList title={item.title} results={item.srcdata} resultid={item.id}  onScreenChange={onScreenChange}></EventList>
            }}            
        />
        </View>

        */
    }
    else
    {
        return <>
        <View style={styles.container}>
          {
              (direction== "column")?null:<View style={[styles.top]}><HeaderRight></HeaderRight></View>
          }


          <View style={[styles.middle]}>
            <View style={styles.emptySchedule}>
                {
                    // <Image source={require('../../assets/nomeetingroom.png')} style={{width: 100, height: 100,alignSelf:'center'}} />
                }
                <Text style={[styles.emptyText,{color:defaultinit.textcolor_right}]}>{language.no_meetings_booked}</Text>
                <Text style={[styles.emptyText,{color:defaultinit.textcolor_right}]}>{language.for_this_room}</Text>
            </View>
          </View>
        </View>
        </>
    }
};

const styles = StyleSheet.create({

    top:{
        flex: 15,
    //    backgroundColor: '#203864',
        justifyContent: 'center'
      },
      middle:{
        flex: 85,
      //  backgroundColor: '#586a8b',
        justifyContent: 'center'
      //  justifyContent: 'flex-end'
      },    
      container: {
        flex: 1,
        justifyContent: 'center',
      //  alignItems: 'center',
      },

    emptySchedule:{
        justifyContent: 'center',
        alignSelf: 'center',
        flex:1,
    },
    emptyText:{
        fontSize:25,
    //    color: '#fff',
        margin: 5,
        alignSelf: 'center',
    }   
});

export default DisplayEventScreen;