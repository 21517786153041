import { Alert, Platform } from 'react-native';
import Swal from 'sweetalert2';
const WindowAlert =(title,desc,action) => {
    if (desc)
    {
      const func_confirm = action[1].onPress;
      const func_cancel = action[0].onPress;
      Swal.fire({
          title: title,
          icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: action[1].text
        }).then((result) => {
          if (result.isConfirmed) {
              func_confirm();
          } else {
              func_cancel();
          }
      })
    }
    else
    {
      Swal.fire(title)
    }
}
const DeviceAlert = (title,desc,action) => {
    if (desc)
    {
      Alert.alert(
          title,
          desc,
          [
              {
                text: "Cancel",
                onPress: action[0].onPress,
                style: "cancel"
              },
              { text: "OK", 
                onPress: action[1].onPress
              }
          ]       
        );
    }
    else
    {
      Alert.alert(
        title
      );
    }
};

const CustomAlert = Platform.OS === 'web' ? WindowAlert : DeviceAlert
export default CustomAlert