import {React, useState, useContext, useEffect,useRef } from 'react';
import { View, StyleSheet, Text,Dimensions,ActivityIndicator,TouchableOpacity,StatusBar,Animated,Platform,ImageBackground,ScrollView} from 'react-native';
import DisplayEventScreen from './DisplayEventScreen';
import ScheduleMeetingScreen from './ScheduleMeetingScreen';
import MainBookedScreen from './MainBookedScreen';
import MainFreeScreen from './MainFreeScreen';
import PersonalScreen from './PersonalScreen';
import NetworkContext from '../context/NetworkContext';

const screen = Dimensions.get("screen"); 
const IndexScreen = ({navigation}) => {

    const [ myscreen, setMyscreen ] = useState('MainBookedScreen');
    const [ mypinunlock, setMypinunlock ] = useState(null);
    const {defaultinit, bookedinfo,eventerror,getEventApi,setEventError,getConfigApi} = useContext(NetworkContext);
    const [direction, setDirection] = useState(null);
    const [hideright, setHideright] = useState(false); 
   

    /*
    const fadeAnim = useRef(new Animated.Value(0)).current;

    const fadeIn = () => {
      // Will change fadeAnim value to 1 in 5 seconds
      const mywidth = ((screen.width>screen.height)?screen.width:screen.height) - StatusBar.currentHeight;
      Animated.timing(fadeAnim, {
        toValue: (mywidth* 0.6) ,
        duration: 50,
        useNativeDriver: (Platform.OS === 'web')?true:false
      }).start();
    };
  
    const fadeOut = () => {
      // Will change fadeAnim value to 0 in 3 seconds
      const mywidth = ((screen.width>screen.height)?screen.width:screen.height) - StatusBar.currentHeight;
      Animated.timing(fadeAnim, {
        toValue: mywidth,
        duration: 30,
        useNativeDriver: (Platform.OS === 'web')?true:false
      }).start();
    };
    */

    useEffect( () => {
    //    fadeIn()
        //alert(fadeAnim)


        if (screen.height > screen.width) //vertical
        {
          setDirection('column');
        }
        else
        {
          setDirection('row')
        } 

        Dimensions.addEventListener(
            "change",
            ({screen }) => {
              //setDimensions({ window, screen });

              if (screen.height > screen.width) //vertical
              {
                setDirection('column');
              }
              else
              {
                setDirection('row')
              }              
            }
          );
    },[]);

    if (!direction){
        return null;
    }
    if (!bookedinfo)
    {
        if (eventerror)
        {
            if (eventerror.errorcode == "104")
            {
                return   <>
                {
                //  <StatusBar barStyle="light-content" backgroundColor="#000"/>
                    <StatusBar hidden />
                }
                <View style={[styles.container,{backgroundColor:defaultinit.bg_main_header_footer}]}>
                    <Text style={styles.title}>
                        {eventerror.errormessage}{"\n\n"}
                        Go to cloud.infinityroom.pro {'>'} Devices, find Token {defaultinit.secret} then assign License Key.
                    </Text>
                    <View style={styles.button}>
                        <TouchableOpacity activeOpacity={0.95} style={styles.button1} onPress = {() => { setEventError(null); getEventApi(); getConfigApi(); } } >
                            <Text style={styles.buttonText}>Try Again</Text>
                        </TouchableOpacity>
                    </View>
                </View>
                </>
            }
            else if (eventerror.errorcode == "101")
            {
                return   <>
                {
                //  <StatusBar barStyle="light-content" backgroundColor="#000"/>
                    <StatusBar hidden />
                }
                <View style={[styles.container,{backgroundColor:defaultinit.bg_main_header_footer}]}>
                    <Text style={styles.title}>
                        Go to cloud.infinityroom.pro {'>'} Devices, find Token {defaultinit.secret} then assign Space & Building.
                    </Text>
                    <View style={styles.button}>
                        <TouchableOpacity activeOpacity={0.95} style={styles.button1} onPress = {() => { setEventError(null); getEventApi(); getConfigApi(); } } >
                            <Text style={styles.buttonText}>Try Again</Text>
                        </TouchableOpacity>
                    </View>
                </View>
                </>
            }            
            else
            {
                return   <>
                {
                //  <StatusBar barStyle="light-content" backgroundColor="#000"/>
                    <StatusBar hidden />
                }
                <View style={[styles.container,{backgroundColor:defaultinit.bg_main_header_footer}]}>
                    <Text style={styles.title}>
                        {eventerror.errormessage}
                    </Text>
                    <View style={styles.button}>
                        <TouchableOpacity activeOpacity={0.95} style={styles.button1} onPress = {() => { setEventError(null); getEventApi(); getConfigApi(); } } >
                            <Text style={styles.buttonText}>Try Again</Text>
                        </TouchableOpacity>
                    </View>
                </View>
                </>
            }
        }
        else
        {
            //return null
            return   <>
            {
            //  <StatusBar barStyle="light-content" backgroundColor="#000"/>
                <StatusBar hidden />
            }
            <View style={styles.container}>
                <ImageBackground
                style={{flex: 1}}
                source={{    uri: defaultinit.background  }}
                >
                <View style={[styles.container,{backgroundColor:defaultinit.bg_main_header_footer}]}>
                <ActivityIndicator size= {80} color="#ffffff" />
                </View>
                </ImageBackground>
            </View>
            </>
        }
    }

    if (defaultinit.theme_type == "personal")
    {
        return <>
        {
        //  <StatusBar barStyle="light-content" backgroundColor="#000"/>
            <StatusBar hidden />
        }
            <View style={styles.container}>
                <ImageBackground
                style={{flex: 1}}
                source={{    uri: defaultinit.background  }}
                >

                    <View style={(direction== "column")?styles.defaultView:styles.defaultView}>
                    <PersonalScreen></PersonalScreen>
                    </View>
                </ImageBackground>
            </View>
        </>
    }
    else
    {

        if (myscreen == "ScheduleMeetingScreen")
        {
            return <>
            {
            //  <StatusBar barStyle="light-content" backgroundColor="#000"/>
                <StatusBar hidden />
            }
            <View style={styles.container}>
                <ImageBackground
                style={{flex: 1}}
                source={{    uri: defaultinit.background  }}
                >

                    <View style={(direction== "column")?styles.defaultView:styles.defaultView}>
                    <ScheduleMeetingScreen direction={direction} hideright={hideright} myscreen={myscreen} onScreenChange={ (newMyscreen,newMypinunlock) => {setMyscreen(newMyscreen); setMypinunlock(newMypinunlock) }}></ScheduleMeetingScreen>
                    </View>
                </ImageBackground>
            </View>
            </>
        }
        else if (myscreen == "MainBookedScreen" || myscreen.includes("MainBookedScreenExtend") || myscreen == "MainBookedScreenBack")
        {
            //if (isbooked)
            if (bookedinfo["isbooked"])
            {
                return <>
                {
                //  <StatusBar barStyle="light-content" backgroundColor="#000"/>
                    <StatusBar hidden />
                }
                            <View style={styles.container}>
                <ImageBackground
                    style={{flex: 1}}
                    source={{    uri: defaultinit.background  }}
                    >
                        <View style={(direction== "column")?styles.defaultViewColumn:styles.defaultViewRow}>
                        <View style={[styles.leftViewFlex,{backgroundColor:defaultinit.bg_main_header_footer}]}>
                        
                            <MainBookedScreen
                                //fadeIn={fadeIn} 
                                //fadeOut={fadeOut}
                                hideright={hideright}
                                onHideRightChange={(newHideright) => { setHideright(newHideright); }} 
                                direction={direction}  
                                myscreen={myscreen} 
                                onScreenChange={ (newMyscreen) => {setMyscreen(newMyscreen)}}  
                                isextend={myscreen.includes("MainBookedScreenExtend")?true:false}
                                eventid={myscreen.includes("MainBookedScreenExtend")?myscreen.substring(myscreen.indexOf('|') + 1):null}
                            />
                        </View>
                        {
                            (direction== "column" || hideright == false)?<View style={[styles.rightView,{backgroundColor:defaultinit.bg_right_list}]}><DisplayEventScreen hideright={hideright} direction={direction} myscreen={myscreen} onScreenChange={ (newMyscreen) => {setMyscreen(newMyscreen)}}/></View>:null
                        }            
                        </View>
                        </ImageBackground>
                    </View>
                </>
            }
            else
            {
                return <>
                <StatusBar hidden />
                <View style={styles.container}>
                <ImageBackground
                    style={{flex: 1}}
                    source={{    uri: defaultinit.background  }}
                    >
                    <View style={(direction== "column")?styles.defaultViewColumn:styles.defaultViewRow}>
                        <View style={[styles.leftViewFlex]}>
                            <MainFreeScreen 
                            //    fadeIn={fadeIn} 
                            //    fadeOut={fadeOut}
                                hideright={hideright}
                                onHideRightChange={(newHideright) => { setHideright(newHideright); }} 
                                direction={direction} 
                                mypinunlock={mypinunlock} 
                                myscreen={myscreen} 
                                onScreenChange={ (newMyscreen) => { setMyscreen(newMyscreen); } } 
                            />                
                        </View>
                        {
                            (direction== "column" || hideright == false)?<View style={[styles.rightView,{backgroundColor:defaultinit.bg_right_list}]}><DisplayEventScreen hideright={hideright} direction={direction}  onScreenChange={ (newMyscreen,newMypinunlock) => { setMyscreen(newMyscreen); setMypinunlock(newMypinunlock)} }/></View>:null
                        }
                        
                    </View>

                </ImageBackground>
                </View>
                </>
            
            }        
        }
    }
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        //alignItems: 'center',
      },
      containerHead:{
          flex: 15, 
        //  height: '20%',
          //alignSelf:'center',
          //backgroundColor: 'rgba(61, 181, 84, 0.4)'
        //  borderColor: 'yellow',
        //  borderWidth: 3
        },
        containerBody: {
              flex: 85,
            //  height: '80%',
              flexDirection: 'row',
              justifyContent: 'center',
              //alignItems: 'center',
            },
          
    
    defaultViewRow: {
        flexDirection: 'row',
        flex:1,
    },
    defaultViewColumn: {
        flexDirection: 'column',
        flex:1,
    },
    defaultView: {
        flex:1,
    },
    leftViewFlex: {
    //    backgroundColor: 'rgba(61, 181, 84, 1)',
        flex: 60,
    },
    rightView: {
        flex: 40,
    //    backgroundColor:'#000'
    },
    container: {
        flex: 1,
        justifyContent: "center",
    //    backgroundColor: 'rgba(33,56,251,0.8)'
    //    backgroundColor: '#203864',
      },

      button:{
        //width: 300,
        alignItems: 'center',
      },
      button1:{
    //    color: '#18e7f5',
        color: '#3db554',
        //flexDirection: 'row', 
      //  height: 60, 
    //    backgroundColor: '#18e7f5',
        backgroundColor: '#3db554',
        alignItems: 'center',
        justifyContent: 'center',
     //   marginTop: 5,
        elevation:3,
        borderRadius: 4
      },
      buttonText:{
        fontSize: 25,
        padding: 10,
        //color: '#2138fb',
        color: '#ffffff',
        fontWeight: 'bold',    
        textAlign: 'center',
      },
      title: {
        fontSize: 25,
        padding: 15,
        color: '#fff',
        fontWeight: 'bold',    
        textAlign: 'center',    
      },
});

export default IndexScreen;