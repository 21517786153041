import en_gb from '../locales/calendar_en.json';
import zh_cn from '../locales/calendar_cn.json';
import ms from '../locales/calendar_ms.json';
import fr_ca from '../locales/calendar_fr.json';
import th from '../locales/calendar_th.json';
import de from '../locales/calendar_de.json';
import hi from '../locales/calendar_hi.json';
import ja from '../locales/calendar_ja.json';
import vi from '../locales/calendar_vi.json';
import my from '../locales/calendar_my.json';
import tl_ph from '../locales/calendar_tlph.json';
import zh_tw from '../locales/calendar_zhtw.json';
import bn_bd from '../locales/calendar_bnbd.json';


const calendar_lang = {
    en_gb,
    zh_cn,
    fr_ca,
    de,
    th,
    ms,
    hi,
    ja,
    vi,
    my,
    tl_ph,
    zh_tw,
    bn_bd
  };


export default calendar_lang;