import {React,useContext} from 'react';
import { View, StyleSheet, Text, TouchableOpacity, Image, Button } from 'react-native';
import NetworkContext from '../context/NetworkContext';
import HeaderPersonal from '../components/HeaderPersonal';
import ContentPersonal from '../components/ContentPersonal';

const PersonalScreen = ({myscreen, onScreenChange, mypinunlock,direction,fadeIn,fadeOut,hideright,onHideRightChange}) => {
  const {defaultinit,language,bookedinfo} = useContext(NetworkContext);

    const ActionScreen = () => {
      return (
        <>
          <View style={styles.container}>
            <View style={[styles.top,{backgroundColor: defaultinit.bg_main_header_footer}]}>
              <HeaderPersonal
                direction={direction}   
                hideright={hideright} 
              ></HeaderPersonal>
            </View> 
            <View style={[styles.middle, {backgroundColor: defaultinit.bg_main_center}]}>
                <ContentPersonal
                    from={0} 
                    direction={direction} 
                    fadeIn={fadeIn} 
                    fadeOut={fadeOut}                
                    hideright={hideright} 
                    onHideRightChange={onHideRightChange}
                    />
            </View>
            <View style={[styles.bottom,{backgroundColor: defaultinit.bg_main_center}]}>
                <View style={{ flexDirection:'row' }}>
                <TouchableOpacity activeOpacity={0.95} style={[styles.bottomButtonStyle,{backgroundColor: (bookedinfo["isbooked"])?defaultinit.bg_main_bookingbar:defaultinit.bg_main_freebar}]}>
                    <Text style={[styles.bottomButtonTextStyle, {color:defaultinit.textcolor_main}]}>
                        {(bookedinfo["isbooked"])?language.busy:language.available}    
                    </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </>
      );
    }
    return ActionScreen()
};

const styles = StyleSheet.create({
  top:{
    flex: 15,
//    backgroundColor: '#203864',
    justifyContent: 'center'
  },
  middle:{
    flex: 75,
  //  backgroundColor: '#586a8b',
    justifyContent: 'center'
  //  justifyContent: 'flex-end'
  },
  bottom:{
    flex: 10,
  //  backgroundColor: '#586a8b',
    justifyContent: 'flex-end'
  },

  container: {
    flex: 1,
    justifyContent: 'center',
  //  alignItems: 'center',
  },
  bottomButtonStyle: {
    //  flexDirection: 'row',
    flex: 45,
    height: 60, 
  //  backgroundColor: '#06b457',
    alignItems: 'center',
    justifyContent: 'center',
    //    marginTop: 5,
    //    elevation:3,
    //   borderRadius: 50,
  },
  bottomButtonTextStyle: {
      fontSize: 24,
  //    color: '#fff',
      fontWeight: 'bold',
  },
});

export default PersonalScreen;