import React, { useState, useEffect } from 'react';
import NetInfo from '@react-native-community/netinfo';
import Moment from 'moment-timezone';
import apibase from '../api/apibase';
import translations from '../api/apilang';
import moment from 'moment';
import 'moment/locale/en-gb';

const NetworkContext = React.createContext();

export const NetworkProvider = ({mycheck,children}) =>{
  const [isOnline, setIsOnline] = useState(true);
  const [duration,setMyDuration] = useState(null);
  const [remaining,setMyRemaining] = useState(null);
  const [bookedendunix,setBookedendunix] = useState(null);
  const [events,setEvents] = useState(null);
  const [isbooked,setIsBooked] = useState(false);
  const [defaultinit,setDefaultinit] = useState(null);
  const [bookedinfo,setBookedinfo] = useState(null);
  const [eventerror,setEventError] = useState(null);
  const [language, setLanguage] = useState(translations['en_gb']);

  const [dateStateCurrentNext, setDateStateCurrentNext] = useState({});

  const filterResultsByDate = (groupdate,allresult) => {
    return allresult.filter( gevent => {
      return ( (Moment.tz(gevent.start,defaultinit.timezone).locale('en-gb').format('YYYY-MM-DD') === groupdate ) && (Moment.tz(gevent.end,defaultinit.timezone).unix() > Moment.tz(defaultinit.timezone).unix()) ) ;
    });
  };

  const getElapsed = () => {
    if (bookedendunix)
    {
      let tmpbookedinfo = bookedinfo;
      let utime = Moment.tz(new Date(),defaultinit.timezone).unix()*1000;
      let diffb = Moment.duration(Moment.tz(new Date(bookedendunix),defaultinit.timezone).diff(Moment.tz(new Date(utime),defaultinit.timezone))); 
      if (diffb.asSeconds() >0)
      {
        tmpbookedinfo["remaining"] = diffb.asSeconds();
        setMyRemaining(diffb.asSeconds());
      }
      else
      {
        tmpbookedinfo["remaining"] = null;
        setMyRemaining(null);
      }
      setBookedinfo(tmpbookedinfo);
    }
  };

  const getConfigApi = async () => {
    if (mycheck){
      apibase.defaults.headers.common['Authorization'] = 'Bearer '+mycheck.api_token;
      await apibase.get('device/deviceconfig?secret='+mycheck.secret+'&app='+mycheck.app)
      .then(res => {
        setDefaultinit(res.data);
      })
      .catch(err => {
        setDefaultinit(err.response.data);
      });
    }
  };

  const getEventApi = async (status) => {
    if (defaultinit && ("api_token" in defaultinit) )
    {
      apibase.defaults.headers.common['Authorization'] = 'Bearer '+defaultinit.api_token;
      const startdate = (Moment.tz(Moment().format('YYYY-MM-DD 00:00:00'), defaultinit.timezone)).unix(); //no need * 1000
      const enddate = (Moment.tz(Moment().format('YYYY-MM-DD 23:59:59'), defaultinit.timezone)).add(defaultinit.dayrange, 'days').unix(); //no need * 1000

    //  const response = await apibase.get('calendar_provider/calendarevents?secret='+defaultinit.secret +'&startDate='+startdate+'&endDate='+enddate);
      apibase.get('calendar_provider/calendarevents?secret='+defaultinit.secret +'&startDate='+startdate+'&endDate='+enddate)
      .then(response => {
        if (response.data.errorcode || (response.data.original && response.data.original.errorcode)) 
        { 
          let errorcode = (response.data.errorcode)?response.data.errorcode:response.data.original.errorcode;
          let errormsg = "Unknown Error";
          if (response.data.errormessage || response.data.original.errormessage)
          {
            errormsg = (response.data.errormessage)?response.data.errormessage:response.data.original.errormessage;
          }
          //101-no space building assign
          //102-third party app access had been removed.
          //103-unsupported platform
            setEventError({errorcode: errorcode ,errormessage: errormsg});
        }
        else
        {
          if (status == "quiet")
          {}
          else
          {
            setBookedinfo(null);
          }
      //    setDateStateCurrentNext([]);

          let myresult = [];
          let tdata = [];
          let gdate = "";
          let b=0;
          let title = "";
          let current = {};
          let mycurrentnext = {};

          for (let i=-1; i < 5; i++)
          {
            gdate = Moment.tz(defaultinit.timezone).add(i, 'days');
            const a = filterResultsByDate(gdate.locale('en-gb').format('YYYY-MM-DD'),response.data);

            if (a.length > 0)
            {
              if (i == 0)
              {
                title = "TODAY : "+gdate.format('ddd DD MMM YYYY'); 
              }
              else if (i == 1)
              {
                title = "Tomorrow : "+gdate.format('ddd DD MMM YYYY'); 
              }
              else
              {
                title = gdate.format('ddd DD MMM YYYY'); 
              }
              myresult[b] = {id: b, title: title, srcdata: a, eventgroupdate: gdate.locale('en-gb').format('YYYY-MM-DD') };
              b++;

              for (var ii=0; ii < a.length; ii++) {
                let start = a[ii].start;
                if (current["cstart"]){
                  if (current["nstart"]){
                    break;
                  }
                  else
                  {
                    current["nstart"] = Moment.tz(new Date(a[ii].start),defaultinit.timezone).unix()*1000;
                    current["nend"] = Moment.tz(new Date(a[ii].end),defaultinit.timezone).unix()*1000;
                    //setDateStateCurrentNext(current);
                    
                    mycurrentnext = current;
                  //  console.log(mycurrentnext["nstart"]);
                  }
                }
                else
                {
                  current["cstart"] = Moment.tz(new Date(a[ii].start),defaultinit.timezone).unix()*1000;
                  current["cend"] = Moment.tz(new Date(a[ii].end),defaultinit.timezone).unix()*1000;
                //  setDateStateCurrentNext(current);
                
                  mycurrentnext = current;

                //  console.log(mycurrentnext["cstart"]);
                }
              }
             // console.log(JSON.stringify(mycurrentnext)); 
            }
          } //end forloop 

          if (Object.keys(mycurrentnext).length === 0) {
            setDateStateCurrentNext({});
          }
          else
          {
            setDateStateCurrentNext(mycurrentnext);
          }

          /*
          if (JSON.stringify(mycurrentnext) !== JSON.stringify(dateStateCurrentNext)) {
            console.log('Array has changed:');
          //  setDateStateCurrentNext(mycurrentnext);
            setDateStateCurrentNext(prevState => ({...prevState, ...mycurrentnext}));
          }
          else
          {
            console.log("nothing happend");
          //  console.log(mycurrentnext);
          }*/


          setEvents(myresult);

          let tmpbookedinfo = [];
          let utime = Moment.tz(new Date(),defaultinit.timezone).unix()*1000;
          let finda = response.data.find(el => ( utime >= (Moment.tz(el.start,defaultinit.timezone).add(-15,'minutes').unix()*1000) && utime < (Moment.tz(el.end,defaultinit.timezone).unix()*1000)) );
          if (finda){
            setIsBooked(true);
            let diffb = Moment.duration(Moment.tz(new Date(finda.end),defaultinit.timezone).diff(Moment.tz(new Date(utime),defaultinit.timezone))); 
            let diffb1 = Moment.duration(Moment.tz(new Date(finda.end),defaultinit.timezone).diff(Moment.tz(new Date(finda.start),defaultinit.timezone))); 
            setMyDuration(diffb1.asSeconds());
            setBookedendunix(finda.end);
            setMyRemaining(diffb.asSeconds());

            tmpbookedinfo["isbooked"] = true;
            tmpbookedinfo["duration"] = diffb1.asSeconds();
            tmpbookedinfo["remaining"] = diffb.asSeconds();
            tmpbookedinfo["eventtitle"] = finda.title;
            tmpbookedinfo["eventid"] = finda.eventid;
            tmpbookedinfo["eventorganizer"] = finda.bookedfrom;
            tmpbookedinfo["bookedstart"] = finda.start;
            tmpbookedinfo["bookedend"] = finda.end;
            setBookedinfo(tmpbookedinfo);
          //  console.log(tmpbookedinfo);
          }
          else
          {
            setIsBooked(false);
            setBookedendunix(null);

            tmpbookedinfo["isbooked"] = false;
            tmpbookedinfo["duration"] = null;
            tmpbookedinfo["remaining"] = null;
            tmpbookedinfo["eventtitle"] = null;
            tmpbookedinfo["eventid"] = null;
            tmpbookedinfo["eventorganizer"] = null;
            tmpbookedinfo["bookedstart"] = null;
            tmpbookedinfo["bookedend"] = null;

            setBookedinfo(tmpbookedinfo);
          }
        }
      })
      .catch(function (error) {
        //console.log("error");
      })
    }
  };

  // effects
  useEffect(() => {
    

    NetInfo.configure({
      reachabilityUrl: 'https://display.infinityroom.pro',
      //reachabilityUrl: 'http://display.infinityroom.pro:19006',
      reachabilityTest: async response => response.status === 200,
      reachabilityLongTimeout: 30 * 1000, // 60s
      reachabilityShortTimeout: 5 * 1000, // 5s
      reachabilityRequestTimeout: 15 * 1000, // 15s
    });

    NetInfo.addEventListener(state => {
      if (state.isConnected && state.isInternetReachable)
      {
        setIsOnline(true);
      }
      else
      {
        setIsOnline(false);
      }
    });

    getConfigApi();
  }, []);

  useEffect(() => {
    if (defaultinit)
    {
      if (translations[defaultinit.language])
      {
        setLanguage(translations[defaultinit.language])
      } 
      //import locale cannot use dynamic var, that's why need hardcode import.
       async function importLocale() {
        const sn = defaultinit.language;
        const newSn = sn.replace(/_/g, "-");
        
        switch(sn) {
          case "en_gb":
            await import("moment/locale/en-gb");
            break;
          case "zh_cn":
            await import("moment/locale/zh-cn");
            break;
          case "ms":
            await import("moment/locale/ms");
            break;
          case "th":
            await import("moment/locale/th");
            break;
          case "de":
            await import("moment/locale/de");
            break;
          case "fr_ca":
            await import("moment/locale/fr-ca");
            break;
          case "hi":
            await import("moment/locale/hi");
            break;
          case "ja":
            await import("moment/locale/ja");
            break;
          case "vi":
            await import("moment/locale/vi");
            break;
          case "my":
            await import("moment/locale/my");
            break;
          case "tl_ph":
            await import("moment/locale/tl-ph");
            break;
          case "zh_tw":
            await import("moment/locale/zh-tw");
            break;
          case "bn_bd":
            await import("moment/locale/bn-bd");
            break;
          default:
            await import("moment/locale/en-gb");
        }
        moment.locale(newSn);
      }

      importLocale();
      getEventApi();
      const intervalCall = setInterval(() => {
        getEventApi("quiet"); 
      }, 60000);
      return(() => {
        clearInterval(intervalCall)
      })
    }
  }, [defaultinit])

  if (!defaultinit)
  {
    return null;
  }

  return (
    <NetworkContext.Provider value={{isOnline ,events, isbooked, duration, remaining, bookedinfo,language, setBookedinfo, eventerror,setEventError,defaultinit,dateStateCurrentNext,setMyRemaining,getEventApi,getElapsed,getConfigApi}}>{children}</NetworkContext.Provider>
  );
}
export default NetworkContext;