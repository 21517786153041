import {React,useContext } from 'react';
import { View, StyleSheet, Text, TouchableOpacity, Image, Button } from 'react-native';
import NetworkContext from '../context/NetworkContext';

const ContentPersonal = ({from,direction,hideright,onHideRightChange}) => {
    const {bookedinfo,defaultinit,language} = useContext(NetworkContext);
    return <View style={styles.defaultViewRow}>
        <View style={[styles.leftViewFlex]}></View>

        <View style={[styles.rightView]}>
            <View style={styles.container}>
                <View style={[styles.middle]}>
                    <Text style={[styles.subject,{color:defaultinit.textcolor_main}]}>{defaultinit.display_name}</Text>
                    <Text style={[styles.position,{color:defaultinit.textcolor_main}]}>{defaultinit.position}</Text>
                    <Text style={[styles.department,{color:defaultinit.textcolor_main}]}>{defaultinit.department}</Text>
                </View>
                
            </View>
        </View>
    </View>
}


const styles = StyleSheet.create({
    arrorStyle: {
        position: 'absolute',
        right: 0,
        paddingRight:0,
    },
    subject:{
        fontSize: 50,
        paddingBottom: 30,
        textTransform:'uppercase',
    //    color: 'white',
        fontWeight: 'bold',    
        //marginBottom: 55,
        alignSelf:'center'
    },
    position:{
        fontSize: 35,
        paddingBottom: 10,
    //    color: 'white',
        fontWeight: 'bold',    
        //marginBottom: 55,
        alignSelf:'center'
    },
    department:{
        fontSize: 30,
     //   paddingBottom: 10,
    //    color: 'white',
     //   fontWeight: 'bold',    
        //marginBottom: 55,
        alignSelf:'center'
    },
    container: {
        flex: 1,
        justifyContent: 'center',
      //  alignItems: 'center',
      },
      top:{
        flex: 30,
    //    backgroundColor: '#203864',
        justifyContent: 'center',
    //    borderBottomWidth: 1,
        //   borderTopWidth:1,
    //       borderColor: '#ccc',
        //   borderStyle: 'dotted',
      },
      middle:{
        flex: 70,
    //    backgroundColor: '#203864',
        justifyContent: 'center'
      },


      defaultViewRow: {
        flexDirection: 'row',
        flex:1,
    },
    defaultViewColumn: {
        flexDirection: 'column',
        flex:1,
    },
    defaultView: {
        flex:1,
    },
    leftViewFlex: {
    //    backgroundColor: 'rgba(61, 181, 84, 1)',
        flex: 30,
    },
    rightView: {
        flex: 70,
    //    backgroundColor:'#000'
    },


})

export default ContentPersonal;