import {React, useState, useEffect } from 'react';
import { View, Text, StyleSheet,Image,ActivityIndicator, StatusBar,TouchableOpacity } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import RNRestart from 'react-native-restart';
import TokenHelp from './TokenHelp';

const TokenContent = () => {
  const [token, setToken] = useState(null);

  const myrestart = () => {
    RNRestart.Restart()
  //   DevSettings.reload();
  }

  const getData = async () => {
    try {
      const value = await AsyncStorage.getItem('@localtoken')
      if(value !== null) {
        setToken(value);
      }
      else
      {
        setToken(null)
      }
    } catch(e) {
        setToken(null);
    }
  }

  useEffect( ()=> {
    getData();
  },[]);


  if (!token)
  {
      return null;
  }
  return <View style={styles.container}>
  <Text style={styles.title}>
    Device Token
  </Text>        
  <Text style={styles.token}>
   {token}
  </Text>
  <View style={styles.button}>
      <TouchableOpacity activeOpacity={0.95} style={styles.button1} onPress = {() => myrestart()} >
          <Text style={styles.buttonText}>PAIR</Text>
      </TouchableOpacity>
  </View>
    <Image
      style={{ width: 100, alignSelf: 'center', height: 100, marginTop: 20 }}
      source={require('../../assets/infinityroom_qr.png')}
      ></Image>
    <TokenHelp></TokenHelp>
</View>    
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // justifyContent: 'center',
    alignItems: 'center',
  },
  button:{
    //width: 300,
    alignItems: 'center',
  },
  button1:{
    //color: '#2138fb',
    color: '#3db554',
    //flexDirection: 'row', 
  //  height: 60, 
    //backgroundColor: '#2138fb',
    backgroundColor: '#3db554',
    alignItems: 'center',
    justifyContent: 'center',
  //   marginTop: 5,
    elevation:3,
    borderRadius: 4
  },
  buttonText:{
    fontSize: 25,
    padding: 10,
    color: 'white',
    fontWeight: 'bold',    
    textAlign: 'center',
  },
  title: {
    fontSize: 25,
    padding: 15,
    color: '#000',
    fontWeight: 'bold',    
    textAlign: 'center',    
  },
  token: {
    fontSize: 25,
    padding: 10,
    color: '#0975c2',
    fontWeight: 'bold',    
    textAlign: 'center',    
  },
});

export default TokenContent;