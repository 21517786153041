import {React } from 'react';
import { View, Text,  StyleSheet,Image, StatusBar } from 'react-native';

const TokenHelp = () => {
  return <>
      <View>
      <Text style={styles.textTitle}>
        Kindly login at cloud.infinityroom.pro and follow the below steps to pair the device : 
      </Text>
      <Text style={styles.textContent}>
        1. Select Devices
      </Text>
      <Text style={styles.textContent}>
        2. Select Add Device 
      </Text>
      <Text style={styles.textContent}>
        3. Enter the 6 digits Device Token
      </Text>
      <Text style={styles.textContent}>
        <Text style={styles.textContent}>4. Select the</Text>
        <Text style={styles.textboldContent}> PAIR</Text>
        <Text style={styles.textContent}> button</Text>
      </Text>
  </View>
  </>
}
const styles = StyleSheet.create({
  textTitle:{
    fontSize: 20, 
    color: '#000',
    paddingLeft:20,
    paddingRight:10,
    paddingBottom:20,
    paddingTop:20
  },
      textContent:{
        fontSize: 18, 
        color: '#000',
        paddingLeft:20,
        paddingBottom:5,
      },
      textboldContent:{
        fontSize: 20,
        fontWeight: 'bold' ,
        color: '#000',
        //padding: 4
      }
});

export default TokenHelp;