import {React, useEffect, useState, useContext} from 'react';
import { View, StyleSheet, Text, Button, TouchableOpacity,Modal, Pressable,TextInput } from 'react-native';
import {Calendar,LocaleConfig} from 'react-native-calendars';
import TimePicker from '../components/TimePicker';
import Moment from 'moment';
import NetworkContext from '../context/NetworkContext';
import CustomAlert from '../components/CustomAlert';
import apibase from '../api/apibase';
import { MaterialIcons } from '@expo/vector-icons';
import EventTitle from '../components/EventTitle';
import ShowPin from '../components/ShowPin';
import Header from '../components/Header';
import test from '../locales/calendar_cn.json';
import calendar_lang from '../api/apilang_calendar';


const ScheduleMeetingScreen = ({myscreen, onScreenChange, mycountdown, onCountdownChange, direction, hideright})=> {

    const {events, defaultinit,language,getEventApi, setMyRemaining,duration,remaining,getElapsed,setBookedinfo,bookedinfo} = useContext(NetworkContext);
    const [todayDate, setTodayDate] = useState(Moment.tz(new Date(),defaultinit.timezone).locale('en-gb').format('YYYY-MM-DD'));
 //   const [todayHourMinute, setTodayHourMinute] = useState(Moment(new Date()).format('HHmm'));
//    const [datePick, setDatePick] = useState(todayDate);
    const [dataSource, setDataSource] = useState([]);
  //  const [initstart, setInitstart] = useState(null); 
    const [mystart, setMyStart] = useState(null);
    const [myend, setMyEnd] = useState(null);
    //const [direction, setDirection] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalpinVisible, setModalPinVisible] = useState(false);
    const [title, setTitle] = useState('');
    const [fromwho, setFromwho] = useState('');
    const [dateState, setDateState] = useState(Moment.tz(new Date(),defaultinit.timezone).locale('en-gb').add(defaultinit.dayrange,'days').format('YYYY-MM-DD'));

    LocaleConfig.locales[defaultinit.language] = (calendar_lang[defaultinit.language])?calendar_lang[defaultinit.language]:calendar_lang['en_gb'];

    LocaleConfig.defaultLocale = defaultinit.language;

    const cancelInsert = () =>{

        getElapsed();
        onScreenChange('MainBookedScreen',false);
    }
    const showTitle = () =>{
        if (defaultinit.pin_required == "1")
        {
            setModalPinVisible(true);            
        }
        else
        { 
            setModalVisible(true);
        }
    }
    const insertEventApi = async () => {
        //const toast = useToast();
        setModalVisible(false);

       // Moment.tz(new Date(),defaultinit.timezone).format("ss")
        let event_start = dataSource.datestring+" "+mystart.hhmm+":"+Moment.tz(new Date(),defaultinit.timezone).locale('en-gb').format("ss");
        let event_end = dataSource.datestring+" "+myend.hhmm+":00";     

        if (defaultinit && ("api_token" in defaultinit) )
        {
            setBookedinfo(null);
            
            apibase.defaults.headers.common['Authorization'] = 'Bearer '+defaultinit.api_token;
            const startdate = Moment.tz(event_start,'YYYY-MM-DD HH:mm:ss',defaultinit.timezone).unix(); //no need * 1000
            let enddate = Moment.tz(event_end,'YYYY-MM-DD HH:mm:ss',defaultinit.timezone).unix(); //no need * 1000                


            if (myend.hhmm < mystart.hhmm)
            {
                //need add 1 day                
                enddate = Moment.tz(event_end,'YYYY-MM-DD HH:mm:ss',defaultinit.timezone).add(1, 'days').unix(); //no need * 1000
            }
//const article = { title: 'Axios POST Request Example' };
            const eventsubmit = { startDate: startdate, endDate: enddate, eventTitle: title, bookedFrom: fromwho};           

            //const response = await apibase.post('calendar_provider/calendarevents?secret='+defaultinit.secret +'&startDate='+startdate+'&endDate='+enddate)
            const response = await apibase.post('calendar_provider/calendarevents?secret='+defaultinit.secret, eventsubmit)
            .then(res => {
                getEventApi();
                onScreenChange('MainBookedScreen')
                //Toast.show('Successfully Booked.', Toast.LONG);
              })
              .catch(error => {
                setBookedinfo(bookedinfo);
                if (error.response)
                {
                    CustomAlert(""+error.response.data.message+"");
                }
                getEventApi();
              });      
        }
        //getEventApi        
    };


    const filterResultsByDate = (groupdate,allresult) => {
        return allresult.filter( gevent => {
            return gevent.eventgroupdate === groupdate; 
        });
      };

    const refreshDatasource = (datePick,todayHourMinute) =>{
        const numColumns = 6;
        let mydata = [];
        let mydatab = [];
        let items = [];
        let hh,mm, tt = 0;
        let x=30;
        let ap = ['AM', 'PM']; 
        let hhmm = "";
        let b=0;
        let displayhhmm = "";
        
        const a = filterResultsByDate(datePick,events);
        (a[0])?mydata["eventselected"] = a[0].srcdata : mydata["eventselected"]=[];

        let previousdate = Moment.tz(datePick,'YYYY-MM-DD',defaultinit.timezone).locale('en-gb').add(-1, 'days').format('YYYY-MM-DD');
        const c = filterResultsByDate(previousdate,events);
        (c[0])?mydatab["eventselected"] = c[0].srcdata : mydatab["eventselected"]=[];

        if (datePick == todayDate) //yyyy-mm-dd
        {
            
              let firstcheck1 = Moment.tz(new Date(),defaultinit.timezone);
              let firstcheck = Moment.tz(new Date(),defaultinit.timezone);
              if (firstcheck.locale('en-gb').format('mm') !== "00" && firstcheck.locale('en-gb').format('mm') !== "30")
              {
                hh= firstcheck.locale('en-gb').format('HH');
                mm = firstcheck.locale('en-gb').format('mm');
                //displayhhmm = (defaultinit.timeformat == "12")?firstcheck.format('h:mmA'):firstcheck.format('HH:mm');
                displayhhmm = (defaultinit.timeformat == "12")?firstcheck1.format('h:mmA'):firstcheck1.format('HH:mm');
                //hhmm = ("0" + (hh)).slice(-2) + ("0" + mm).slice
                let myutime = datePick+" "+hh+""+mm+"59";
                let utime = Moment.tz(myutime,'YYYY-MM-DD HHmmss',defaultinit.timezone).unix()*1000;

                let findb = mydatab["eventselected"].find(el => ( utime >= (Moment.tz(el.start,defaultinit.timezone).unix()*1000) && utime < (Moment.tz(el.end,defaultinit.timezone).unix()*1000) ) );
                if (findb)
                {
                    items[b] = { id: b, src: '' + ("0" + (hh)).slice(-2) + ':' + ("0" + mm).slice(-2), srcdisplay: displayhhmm, status: 'occupied' };
                    b++;
                }
                else
                {                
                    let finda = mydata["eventselected"].find(el => ( utime >= (Moment.tz(el.start,defaultinit.timezone).add(-15,'minutes').unix()*1000) && utime < (Moment.tz(el.end,defaultinit.timezone).unix()*1000) ) );
                    if (finda)
                    {
                        items[b] = { id: b, src: '' + ("0" + (hh)).slice(-2) + ':' + ("0" + mm).slice(-2), srcdisplay: displayhhmm, status: 'occupied' };
                    }
                    else
                    {
                        items[b] = { id: b, src: '' + ("0" + (hh)).slice(-2) + ':' + ("0" + mm).slice(-2), srcdisplay: displayhhmm};
                    }
                    b++
                }
              }

              for (let i=0; tt < 36*60; i++)
              {
                  hh= Math.floor(tt/60);
                  mm = (tt%60);
                  hhmm = ("0" + (hh)).slice(-2) + ("0" + mm).slice(-2);  

  
                  if (hhmm >= todayHourMinute)
                  {
                    let myutime = datePick+" "+hhmm+"59";

                    let myhm = 1000;
                    let utime = Moment.tz(myutime,'YYYY-MM-DD HHmmss',defaultinit.timezone).unix()*1000;
                    displayhhmm = (defaultinit.timeformat == "12")?Moment.tz(myutime,'YYYY-MM-DD HHmmss',defaultinit.timezone).format('h:mmA'):Moment.tz(myutime,'YYYY-MM-DD HHmmss',defaultinit.timezone).format('HH:mm');
                    
                    let findb = mydatab["eventselected"].find(el => ( utime >= (Moment.tz(el.start,defaultinit.timezone).unix()*1000) && utime < (Moment.tz(el.end,defaultinit.timezone).unix()*1000) ) );
                    if (findb)
                    {
                        items[b] = { id: b, src: '' + ("0" + (hh)).slice(-2) + ':' + ("0" + mm).slice(-2),srcdisplay: displayhhmm,  status: 'occupied' };
                        b++;
                    }
                    else
                    {
                        let finda = mydata["eventselected"].find(el => ( utime >= (Moment.tz(el.start,defaultinit.timezone).unix()*1000) && utime < (Moment.tz(el.end,defaultinit.timezone).unix()*1000) ) );
                        if (finda)
                        {
                            items[b] = { id: b, src: '' + ("0" + (hh)).slice(-2) + ':' + ("0" + mm).slice(-2), srcdisplay: displayhhmm, status: 'occupied' };
                        }
                        else
                        {
                        items[b] = { id: b, src: '' + ("0" + (hh)).slice(-2) + ':' + ("0" + mm).slice(-2) ,srcdisplay: displayhhmm};
                        }
                        b++;
                    }
                  }
                  tt = tt +x;
              }
          }
          else
          {
            for (let i=0; tt < 36*60; i++)
            {
                hh= Math.floor(tt/60);
                mm = (tt%60);
                hhmm = ("0" + (hh)).slice(-2) + ("0" + mm).slice(-2);
                
                let myutime = datePick+" "+hhmm+"59";

                    let myhm = 1000;
                    let utime = Moment.tz(myutime,'YYYY-MM-DD HHmmss',defaultinit.timezone).unix()*1000;
                    displayhhmm = (defaultinit.timeformat == "12")?Moment.tz(myutime,'YYYY-MM-DD HHmmss',defaultinit.timezone).format('h:mmA'):Moment.tz(myutime,'YYYY-MM-DD HHmmss',defaultinit.timezone).format('HH:mm');


                    let findb = mydatab["eventselected"].find(el => ( utime >= (Moment.tz(el.start,defaultinit.timezone).unix()*1000) && utime < (Moment.tz(el.end,defaultinit.timezone).unix()*1000) ) );
                    if (findb)
                    {
                        items[b] = { id: b, src: '' + ("0" + (hh)).slice(-2) + ':' + ("0" + mm).slice(-2), srcdisplay: displayhhmm, status: 'occupied' };
                        b++;
                    }
                    else
                    {
                    
                        let finda = mydata["eventselected"].find(el => ( utime >= (Moment.tz(el.start,defaultinit.timezone).unix()*1000) && utime < (Moment.tz(el.end,defaultinit.timezone).unix()*1000) ) );
                        if (finda)
                        {
                            items[b] = { id: b, src: '' + ("0" + (hh)).slice(-2) + ':' + ("0" + mm).slice(-2), srcdisplay: displayhhmm, status: 'occupied' };
                        }
                        else
                        {
                    items[b] = { id: b, src: '' + ("0" + (hh)).slice(-2) + ':' + ("0" + mm).slice(-2) ,srcdisplay: displayhhmm };
                        }
                        b++;
                    }
                tt = tt +x;
            }
          }
  
        const numberOfFullRows = Math.floor(items.length / numColumns);
  
          let numberOfElementsLastRow = items.length - (numberOfFullRows * numColumns);
          while (numberOfElementsLastRow !== numColumns && numberOfElementsLastRow !== 0) {
              items.push({ src: `blank-${numberOfElementsLastRow}`, status: 'empty' });
              numberOfElementsLastRow++;
          }        
          mydata["datestring"] = datePick;
          mydata["data"] = items;
          //mydata["data"] = [];
        
        setDataSource(mydata);
        //setDataSource();
        setMyStart(null);
        setMyEnd(null);
        };

    
    useEffect( ()=> {
        refreshDatasource(todayDate,Moment.tz(new Date(),defaultinit.timezone).locale('en-gb').format('HHmm'));         
        let intervalId =  setInterval(() => setDateState( Moment.tz(new Date(),defaultinit.timezone).locale('en-gb').add(defaultinit.dayrange,'days').format('YYYY-MM-DD') ), 1000);
        return(() => {
            clearInterval(intervalId)
        })        
    },[defaultinit]);

if (!direction){
    return null;
}
        return  <View style={styles.container}>
        <View style={[(direction== "column")?styles.topR:styles.top,{backgroundColor: defaultinit.bg_main_header_footer}]}>
          <Header
            direction={direction}   
            hideright={hideright}
            iscalendar={1} 
          ></Header>
        </View>
        <View style={[(direction== "column")?styles.middleR:styles.middle, {backgroundColor: defaultinit.bg_main_center}]}>
        
        
        
        <View style={(direction== "column")?styles.defaultViewColumn:styles.defaultViewRow}>
            <View style={[styles.leftView,{backgroundColor:defaultinit.bg_main_header_footer}]}>
                <View style={{ flexDirection:'column',flex:1 }}>
                    <View style={[styles.timePickerStyle,{backgroundColor:defaultinit.bg_main_header_footer}]}>
                        <TimePicker 
                            dataSource={dataSource} 
                            mystart={mystart} 
                            onMyStartChange={ newMystart => setMyStart(newMystart)}
                            myend={myend} 
                            onMyEndChange={ newMyend => setMyEnd(newMyend)}
                        ></TimePicker>
                    </View>
                    <View style={{ flex:5}}>
                        <View style={styles.parentView1}>
                            <View style={{ flexDirection:'row' }}>
                            <TouchableOpacity activeOpacity={0.95} style={[styles.bottomButtonStyle,{backgroundColor:defaultinit.bg_main_bookingbar}]} onPress = { ()=> cancelInsert()} >
                                <Text style={[styles.bottomButtonTextStyle,{color:defaultinit.textcolor_main}]}>{language.cancel}</Text>
                            </TouchableOpacity>
                            { mystart && myend ? <TouchableOpacity activeOpacity={0.95} style={[styles.bottomButtonScheduleStyle,{backgroundColor:defaultinit.bg_main_freebar}]} onPress = { ()=> showTitle() } ><Text style={[styles.bottomButtonTextStyle,{color:defaultinit.textcolor_main}]}>{language.schedule_now}</Text></TouchableOpacity> : null }
                            </View>
                        </View>
                    </View>
                </View>
            </View>
            <View style={[styles.rightView,{backgroundColor:defaultinit.bg_right_list}]}>                
            <Calendar
                markingType={'period'}
                hideExtraDays={true}
                onDayPress={dateObject => {
                    refreshDatasource(dateObject.dateString,Moment.tz(new Date(),defaultinit.timezone).locale('en-gb').format('HHmm'));
                  }}
                //initialDate={'2022-11-22'}
                // Minimum date that can be selected, dates before minDate will be grayed out. Default = undefined
                minDate={todayDate}
                // Maximum date that can be selected, dates after maxDate will be grayed out. Default = undefined
                maxDate={dateState} 
                hideArrows={false}
                // Replace default arrows with custom ones (direction can be 'left' or 'right')                
                renderArrow={(direction) => direction === 'left' ? <MaterialIcons name="keyboard-arrow-left" size={24} color={defaultinit.textcolor_right} /> : <MaterialIcons name="keyboard-arrow-right" size={24} color={defaultinit.textcolor_right} />}

                theme={{
                    calendarBackground: defaultinit.bg_right_list,                    
                    textSectionTitleColor: defaultinit.textcolor_right,
                    dayTextColor: defaultinit.textcolor_right,
                    monthTextColor: defaultinit.textcolor_right,
                    textDisabledColor: 'rgba(204, 204, 204, 0.5)',
                    textDayFontSize: 26,
                    textMonthFontSize: 26,
                    textDayHeaderFontSize: 18
                  }}
                
                />            
            </View>
            <EventTitle 
                direction={direction} 
                titlesubmit={insertEventApi} 
                modalVisible={modalVisible} 
                setModalVisible={setModalVisible} 
                title={title} 
                onTitleChange={(newTitle) => setTitle(newTitle)}
                fromwho={fromwho} 
                onFromwhoChange={(newFromwho) => setFromwho(newFromwho)}                
            ></EventTitle>
            {
                (defaultinit.pin_required == "1")?<ShowPin direction={direction} modalpinVisible={modalpinVisible} setModalPinVisible={setModalPinVisible} setModalVisible={setModalVisible} from={0}></ShowPin>:null
            }
        </View>
        </View>
        
        </View>
};

const styles = StyleSheet.create({

      top:{
        flex: 15,
    //    backgroundColor: '#203864',
        justifyContent: 'center'
      },
      middle:{
        flex: 85,
      //  backgroundColor: '#586a8b',
        justifyContent: 'center'
      //  justifyContent: 'flex-end'
      },
      topR:{
        flex: 9,
    //    backgroundColor: '#203864',
        justifyContent: 'center'
      },
      middleR:{
        flex: 91,
      //  backgroundColor: '#586a8b',
        justifyContent: 'center'
      //  justifyContent: 'flex-end'
      },
      bottom:{
        flex: 20,
      //  backgroundColor: '#586a8b',
        justifyContent: 'flex-end'
      },
      container: {
        flex: 1,
        justifyContent: 'center',
      //  alignItems: 'center',
      },



    defaultViewRow: {
        flexDirection: 'row',
        flex:1,
    },
    defaultViewColumn: {
        flexDirection: 'column',
        flex:1,
    },
    timePickerStyle:{
        flex:60,
    //    backgroundColor:'#000000'
    },

        defaultView: {
            flexDirection: 'row',
            flex:1,
        },
        leftView: {
            flex: 60,
        //    backgroundColor: 'rgba(61, 181, 84, 0.8)',
        },
        rightView: {
            flex: 40,
        //    backgroundColor:'#000'
        },
        parentView1:{
              flex:50,
          //    flexDirection: 'row',
            //  justifyContent: 'center',
              width: '100%',
              alignSelf: 'center',
              bottom: 0,
             position: 'absolute'
          },
        bottomButtonStyle: {
            flex: 50,
                height: 60, 
           //     backgroundColor: '#ff0000',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: 5,
                elevation:3,
             //   borderRadius: 50,
            },
            bottomButtonScheduleStyle: {
                flex: 50,
                    height: 60, 
                //    backgroundColor: '#41ab6d',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: 5,
                    elevation:3,
                 //   borderRadius: 50,
                },        
        bottomButtonTextStyle: {
            fontSize: 24,
        //    color: '#fff',
            //fontWeight: 'bold',
        },
});

export default ScheduleMeetingScreen;