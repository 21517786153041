
import {createStackNavigator} from 'react-navigation-stack';
import {createAppContainer} from 'react-navigation';

import IndexScreen from './src/screens/IndexScreen';
import NoPermissionScreen from './src/screens/NoPermissionScreen';
import ShowTokenScreen from './src/screens/ShowTokenScreen';
import ShowNoConnectionScreen from './src/screens/ShowNoConnectionScreen';

import { NetworkProvider } from './src/context/NetworkContext';
import Header from './src/components/Header';
import HeaderDevice from './src/components/HeaderDevice';
import {React, useState, useEffect } from 'react';
import { View,StyleSheet,ActivityIndicator,StatusBar,Platform} from 'react-native';
import apibase from './src/api/apibase';
import ShortUniqueId from 'short-unique-id';
import AsyncStorage from '@react-native-async-storage/async-storage';

const MyHeader = (mystatus) => {
  return {
    headerTintColor: '#000',
    headerMargin:0,
    headerTitle: () => <Header />,
    headerShown: mystatus,                      
    headerStyle: {
        backgroundColor: '#000',                
        height: 100,        
        elevation: 0,
      //  borderWidth:3,
      //  borderColor:'green',
        borderBottomWidth: 0
    }
  };
}

const MyHeaderDevice = (mystatus) => {
  return {
    headerTintColor: '#fff',
    headerTitle: () => <HeaderDevice />,
    headerShown: mystatus,                      
    headerStyle: {
        backgroundColor: '#fff',                
        height: 120,
        elevation: 0,
        borderBottomWidth: 0
    }
  };
}

const Home = createStackNavigator(
  {
    Index: IndexScreen,
  },
  {
    initialRouteName: 'Index',
    defaultNavigationOptions: () => {
    //  return MyHeader(true)
      return MyHeader(false)
    }
  }
);

const Home1 = createStackNavigator(
  {
    Index: NoPermissionScreen,
  },
  {
    initialRouteName: 'Index',
    defaultNavigationOptions: () => {
      return MyHeader(false)
    }
  }
);

const Home2 = createStackNavigator(
  {
    Index: ShowTokenScreen,
  },
  {
    initialRouteName: 'Index',
    defaultNavigationOptions: () => {
      return MyHeaderDevice(true)
    }
  }
);

const Home3 = createStackNavigator(
  {
    Index: ShowNoConnectionScreen,
  },
  {
    initialRouteName: 'Index',
    defaultNavigationOptions: () => {
      return MyHeaderDevice(true)
    }
  }
);

const App = createAppContainer(Home);
const App1 = createAppContainer(Home1);
const App2 = createAppContainer(Home2);
const App3 = createAppContainer(Home3);

export default () => {
  const [mycheck, setMyCheck] = useState(null);
  const [mychecktmp, setMyChecktmp] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getPermission = async () => {
    let secret = null;
    let app = null

    if (Platform.OS === 'web') {
      const parsedUrl = new URL(window.location.href);
      secret = parsedUrl.searchParams.get('secret');
      app = "web";
    }
    else if (Platform.OS === 'android' || Platform.OS === 'ios') {      
      const value = await AsyncStorage.getItem('@localtoken')
      if(value !== null) {
        secret = value;
      }
      else
      {
        const uid = new ShortUniqueId({ length: 6 });
        secret = uid();
        await AsyncStorage.setItem('@localtoken', secret);
      }
      app = "tablet";
    }
    const response = await apibase.get('device/checkauthorized?secret='+secret+'&app='+app)
    .then(res => {
      setMyChecktmp(res.data);
    //setMyChecktmp({"status": null, "noconnection": 1});
    //setIsLoading(false);
    })
    .catch(err => {
      setIsLoading(false);
      //  console.log(err.response)
      //  setMyChecktmp({"status": null, "noconnection": 1});
    });
  };

  useEffect( () => {
    getPermission();
  },[]);

  if (!mychecktmp)
  { 
    return (
    <>
      {
      //  <StatusBar barStyle="light-content" backgroundColor="#000"/>
          <StatusBar hidden />
      }
      <View style={[styles.container,{backgroundColor:'#ffffff'}]}>
      {isLoading ? (
      <ActivityIndicator size= {80} color="blue" />
      ) : (
        <App3/>
      )}
      </View>
    </>
    )
  }
  

  return <NetworkProvider mycheck={mychecktmp} >
  {
    //((Platform.OS === 'web' && mycheck.mydata.license === 'valid') || Platform.OS === 'android') ? <App /> : <App1 /> 
    ((Platform.OS === 'web' && mychecktmp.status === true) ||( (Platform.OS === 'android' || Platform.OS === 'ios') && mychecktmp.status === true))? <App /> : 
    (Platform.OS === 'android' || Platform.OS === 'ios' )? <App2/>:<App1 /> 
  }
  </NetworkProvider>
};

const styles = StyleSheet.create({

  container: {
      flex: 1,
      justifyContent: "center",
  //    backgroundColor: 'rgba(33,56,251,0.8)'
  //    backgroundColor: '#203864',
    }
  });
