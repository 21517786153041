import {React,useRef,useState,useEffect,useContext} from 'react';
import { View, StyleSheet, Text, TouchableOpacity,Modal,TextInput } from 'react-native';
import NetworkContext from '../context/NetworkContext';
import { Entypo } from '@expo/vector-icons';
import { Ionicons } from '@expo/vector-icons';
import ReactNativePinView from "react-native-pin-view";

const ShowPin = ({from,modalpinVisible,setModalPinVisible,direction,fastEvent,setModalVisible,quickaction}) => {
    const pinView = useRef(null);
    const [enteredPin, setEnteredPin] = useState("");
    const {defaultinit} = useContext(NetworkContext);

    useEffect(() => {
      if (enteredPin.length > 0) {
        //setShowRemoveButton(true)
      } else {
        //setShowRemoveButton(false)
      }
      if (enteredPin.length === 4) {
          if (enteredPin == defaultinit.pin)
          {
            
            if (from == "1")  //extendevent
            {
              fastEvent();
            }
            else if (from == "0") //free
            {
              setModalVisible(true);
            }
            else if (from == "2") // eventlist quick start/cancel
            {
              quickaction();
            }
            setModalPinVisible(false);
          }
          else
          {
            pinView.current.clearAll()
          }
  
        //setShowCompletedButton(true)
      } else {
        //setShowCompletedButton(false)
      }
    }, [enteredPin])

    return <View style={styles.defaultView}>
    <Modal
        animationType="fade"
        transparent={true}
        visible={modalpinVisible}
        onRequestClose={() => {
        setModalPinVisible(!modalpinVisible);
        }}
    >
        <View style={styles.centeredView}>
        <View style={(direction== "column")?[styles.modalViewColumn,{backgroundColor:defaultinit.bg_pin}]:[styles.modalView,{backgroundColor:defaultinit.bg_pin}]}>
            <Entypo name="circle-with-cross" size={24} color={defaultinit.textcolor_main} style={{position: 'absolute', top: 10, right: 0, paddingRight:10}} onPress = { () => setModalPinVisible(!modalpinVisible)}/>
            
        <ReactNativePinView
            inputSize={12}
            ref={pinView}
            pinLength={4}
            buttonSize={60}
            onValueChange={value => setEnteredPin(value)}
            buttonAreaStyle={{
              marginTop: 10,
              marginLeft: "10%",
              marginRight: "10%",
            }}
            inputAreaStyle={{
              marginBottom: 6,
              //marginLeft: "50%",
              //marginRight: "50%",
            }}
            inputViewEmptyStyle={{
              backgroundColor: "transparent",
              borderWidth: 1,
              borderColor: defaultinit.textcolor_main,
            }}
            inputViewFilledStyle={{
              backgroundColor: defaultinit.textcolor_main,
            }}
            buttonViewStyle={{
              borderWidth: 1,
              borderColor: defaultinit.textcolor_main,
            }}
            buttonTextStyle={{
              color: defaultinit.textcolor_main,
            }}
            onButtonPress={key => {
              if (key === "custom_left") {
                //pinView.current.clear()
            //    getElapsed(); 
            //    onScreenChange('MainBookedScreen',false);
              }
              if (key === "custom_right") {
                pinView.current.clear()
              }
            }}
          //  customLeftButton= {from == "bookedscreen"?<Ionicons name="close-circle-outline" size={54} color="white" />: undefined }
            customRightButton={<Ionicons name="arrow-back-circle-outline" size={54} color={defaultinit.textcolor_main} />}
          />
        </View>
      </View>
    </Modal>
</View>
}

const styles = StyleSheet.create({
  defaultView: {
    flexDirection: 'row',
  //   flex:1,
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalViewColumn:{
    margin: 20,
   // backgroundColor: "#5f7eb7",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modalView: {
    margin: 20,
//    backgroundColor: "#5f7eb7",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,    
    width: '50%'
  }
});

export default ShowPin;