import {React, useEffect, useState} from 'react';
import { View, StyleSheet,Dimensions,StatusBar } from 'react-native';
import TokenHelp from '../components/TokenHelp';
import TokenContent from '../components/TokenContent';

const screen = Dimensions.get("screen");

const ShowTokenScreen = ({navigation}) => {    
    const [direction, setDirection] = useState(null);

    
    useEffect( () => {
        if (screen.height > screen.width) //vertical
        {
          setDirection('column');
        }
        else
        {
          setDirection('row')
        } 

        Dimensions.addEventListener(
            "change",
            ({ screen }) => {
              if (screen.height > screen.width) //vertical
              {
                setDirection('column');
              }
              else
              {
                setDirection('row') 
              }              
            }
          );
    },[]);

        return <>
        {
        //  <StatusBar barStyle="light-content" backgroundColor="#000"/>
            <StatusBar hidden />
        }
        <View style={(direction== "column")?styles.defaultViewColumn:styles.defaultViewRow}>
          {              
              <><View style={styles.leftView}><TokenContent></TokenContent></View>
              </>
          }
    </View>
    </>
};

const styles = StyleSheet.create({

  defaultViewRow: {
    flexDirection: 'row',
    flex:1,
  },
  defaultViewColumn: {
      flexDirection: 'column',
      flex:1,
  },
  leftView: {
      flex: 40,
      backgroundColor: '#fff',
  },
  rightView: {
      flex: 60,
      backgroundColor:'#fff'
  },
});

export default ShowTokenScreen;