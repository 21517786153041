import {React, useState, useContext, useEffect } from 'react';
import { View, Text, FlatList , StyleSheet } from 'react-native';
import Moment from 'moment-timezone';
import NetworkContext from '../context/NetworkContext';
import {Feather} from '@expo/vector-icons';

const EventList = ({title, results, resultid,onScreenChange,showAction}) => {
    const {defaultinit,language,bookedinfo,dateStateCurrentNext,getEventApi,setBookedinfo} = useContext(NetworkContext);
    const [dateState, setDateState] = useState(Moment.tz(new Date(),defaultinit.timezone).unix()*1000);

    useEffect(() => {
        let intervalId = setInterval(() => setDateState( Moment.tz(new Date(),defaultinit.timezone).unix()*1000 ), 1000);
        return(() => {
            clearInterval(intervalId)
        })
    }, [defaultinit]);

    const extendEvent = (eventid) => {
        onScreenChange('MainBookedScreenExtend|'+eventid);
    }
    const endEvent = (eventid,iswholeday) => {
        showAction("end",eventid,iswholeday);
    };

    const cancelEvent = (eventid) => {
        showAction("cancel",eventid,"");
    };

    const startEvent = (eventid) => {
        showAction("start",eventid,"");
    };

    const renderStatus = (item,status) => {
        if (status == "cancel" && defaultinit.is_interactive_button == "1")
        {
            return <View style={{    position: 'absolute',     right: 0,     paddingRight:10, flexDirection:'row'}}>                    
            <View style={styles.textBorderStyle1}><Text style={styles.textSmallStyle} onPress = { () => cancelEvent(""+item.eventid+"") }>{language.cancel}</Text></View>
            </View>
        }
        else if (status == "end" && defaultinit.is_interactive_button == "1")
        {
            return  <View style={{    position: 'absolute',     right: 0,     paddingRight:10, flexDirection:'row'}}>                        
            <View style={styles.textBorderStyle1}><Text style={styles.textSmallStyle} onPress = { () => endEvent(""+item.eventid+"",""+item.wholeDayEvent) }>{language.end}</Text></View>
            </View>
        }
        else if (status == "startcancel" && defaultinit.is_interactive_button == "1")
        {
            return  <View style={{    position: 'absolute',     right: 0,     paddingRight:10, flexDirection:'row'}}>
            <View style={styles.textBorderStyle}><Text style={styles.textSmallStyle}  onPress = { () => startEvent(""+item.eventid+"") } >{language.start}</Text></View>
            <View style={styles.textBorderStyle1}><Text style={styles.textSmallStyle} onPress = { () => cancelEvent(""+item.eventid+"") }>{language.cancel}</Text></View>
            </View>
        }
        else
        {
            return null;
        }
    };

    const getRenderDetail = (item,status,isupcomingdown,isupcomingup) =>{
        return (
         <View>
            {
                (isupcomingup)?<View style={[styles.containerUpcomingStyle,{backgroundColor: defaultinit.bg_main_footer }]}><Text style={[styles.textEventStyle,{color:defaultinit.textcolor_main}]}>{language.upcoming_meeting}</Text></View>:null
            }            
            <View style={styles.containerTitleStyle}>
                <Text style={[styles.textBoldEventStyle,{color:defaultinit.textcolor_right}]}>
                {
                    item.title
                }
                </Text>
            </View>
            <View style={(status)?[styles.containerStyle,{padding: 6}]:styles.containerStyle}>
                <Text style={[styles.textEventStyle,{color:defaultinit.textcolor_right}]}>
                {
                    item.bookedfrom
                }
                </Text>
                {
                    renderStatus(item,status)
                }
            </View>
            
            <View style={(status)?[styles.containerStyle,{paddingBottom: 8}]:styles.containerEndStyle}>
                <Text style={[styles.textEventStyle,{color:defaultinit.textcolor_right}]}>
                {
                    Moment.tz(new Date(item.start),defaultinit.timezone).format('ddd, DD MMM YYYY')
                }
                </Text>
                <Text style={[styles.textEventStyle,{color:defaultinit.textcolor_right}]}>|</Text>
                <Text style={[styles.textEventStyle,{color:defaultinit.textcolor_right}]}>
                {
                    (defaultinit.timeformat == "12")?Moment.tz(new Date(item.start),defaultinit.timezone).format('h:mmA'):Moment.tz(new Date(item.start),defaultinit.timezone).format('HH:mm')
                }
                </Text>
                <Feather name="arrow-right" size={15} style={[styles.textEventStyle,{color:defaultinit.textcolor_right}]} ></Feather>
                <Text style={[styles.textEventStyle,{color:defaultinit.textcolor_right}]}>
                {
                    (defaultinit.timeformat == "12")?Moment.tz(new Date(item.end),defaultinit.timezone).format('h:mmA'):Moment.tz(new Date(item.end),defaultinit.timezone).format('HH:mm')
                }
                </Text>
            </View>
            {
                (isupcomingdown)?(dateStateCurrentNext["nstart"])?<View style={[styles.containerUpcomingStyle,{backgroundColor: defaultinit.bg_main_footer }]}><Text style={[styles.textEventStyle,{color:defaultinit.textcolor_main}]}>{language.upcoming_meeting}</Text></View>:null:null
            }
        </View>
        );
    };

    const renderItem = ({ item, index }) => {
        if (index ===0 && resultid===0 && bookedinfo["isbooked"]) {
            if (dateState < item.start)
            {
                return getRenderDetail(item,"cancel",true,null);
            }
            else
            {
                return getRenderDetail(item,"end",true,null);
            }
        }
        else 
        {            
            const itemstart = Moment.tz(item.start,defaultinit.timezone).add(-defaultinit.showstartadvance,'minutes').unix()*1000;
            if (index ===0 && resultid===0 && defaultinit.showstart == "1") {
                if (dateState < itemstart)
                {
                    return getRenderDetail(item,null,null,true);
                }
                else
                {
                    return getRenderDetail(item,"startcancel",true,null);
                }
            }
            else
            {
                return getRenderDetail(item,null,null,null);
            }
        }
    }

    return <View>    
    <FlatList
        data={results}
        keyExtractor={ result => result.id}
        renderItem={renderItem}            
    />
    </View>
};

const styles = StyleSheet.create({
    groupdate: {
        fontSize:28,
        fontWeight:'bold',
        padding: 8,
        paddingLeft: 30,
    },
  containerStyle: {
    borderBottomWidth: 0,
    fontSize:22,
    paddingLeft: 30,
    flexDirection: 'row',
    position: 'relative',
    alignItems: 'center',
  },
  containerUpcomingStyle: {
    borderBottomWidth: 0,
    fontSize:22,
    paddingLeft: 30,
    height:40,
    flexDirection: 'row',
    position: 'relative',
    alignItems: 'center',
  },
  containerEndStyle: {
    borderBottomWidth: 1,
    borderColor: '#fff',
    fontSize:22,
    paddingLeft: 30,
    paddingBottom:8,
    flexDirection: 'row',
    position: 'relative',
    alignItems: 'center',
  },
  containerTitleStyle: {
    fontSize:22,
    paddingLeft: 30,
    height:55,
    flexDirection: 'row',
    position: 'relative',
    alignItems: 'center',
  },
  textEventStyle:{
    fontSize: 18, 
//    color: '#8a8a92',
    alignSelf: 'center',
    //padding: 4,
    paddingLeft:2,
    paddingRight:2,
  },
  textBoldEventStyle:{
    fontSize: 30, 
 //   color: '#fff',
    alignSelf: 'center',
    padding: 4,
    fontWeight: 'bold'
  },
  textTodayEventStyle:{
    fontSize: 22, 
 //   color: '#fff',
    alignSelf: 'center',
    fontWeight: 'bold',
    padding: 4
  },

  textSmallStyle:{
    fontSize: 15, 
    color: '#ffffff',
    alignSelf: 'center',
    padding: 5,
  },
  textBorderStyle:{
    backgroundColor: "#06B457",
    borderColor: '#06B457',
    borderWidth: 1,
    borderRadius:6,
    width: 80,
    margin:4
  },  
  textBorderStyle1:{
    backgroundColor: "#FF0000",
    borderColor: '#FF0000',
    borderWidth: 1,
    borderRadius:6,
    width: 80,
    margin:4
  },  
});

export default EventList;
