import {React, useState, useContext, useEffect } from 'react';
import { View, StyleSheet, Text, TouchableOpacity, ActivityIndicator } from 'react-native';
import NetworkContext from '../context/NetworkContext';
import CustomAlert from './CustomAlert';
import apibase from '../api/apibase';
import Moment from 'moment-timezone';
import EventTitle from './EventTitle';
import ShowPin from './ShowPin';
import { MaterialCommunityIcons } from '@expo/vector-icons';



const FastBookButton = ({from,onScreenChange,eventid,direction}) => {
    const {defaultinit,language,dateStateCurrentNext,bookedinfo,setBookedinfo,getEventApi} = useContext(NetworkContext);
    const [totalmin,setTotalMin] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalpinVisible, setModalPinVisible] = useState(false);
    const [title, setTitle] = useState('');
    const [fromwho, setFromwho] = useState('');
    const [mymin, setMymin] = useState(null);

    const showTitle = (addmin) =>{    
       /* if (defaultinit.pin_required == "1")
        {
            setModalPinVisible(true);            
        }
        else
        { */
            setMymin(addmin);
            if (eventid && eventid !="")
            {
                if (defaultinit.pin_required == "1")
                {
                    setModalPinVisible(true);            
                }
                else
                { 
                    fastEvent(addmin);    
                }            
            }
            else
            {
                //    alert("2");
                if (defaultinit.pin_required == "1")
                {
                    setModalPinVisible(true);            
                }
                else
                { 
                    setModalVisible(true);
                }                
            }
     //   }
    }

    const fastEvent = async (addminextend) => {
        
        //const toast = useToast();

        if (defaultinit && ("api_token" in defaultinit) )
        {
            if (eventid && eventid !="")
            {
                setModalVisible(false);

                //const addmin = addminextend;
                let addmin = (addminextend)?addminextend:mymin;
                //let addmin = mymin;           
                //bookedend                   
                apibase.defaults.headers.common['Authorization'] = 'Bearer '+defaultinit.api_token;
                //const enddate = "456";
                //const startdate = Moment.tz(event_start,'YYYY-MM-DD HH:mm:ss',defaultinit.timezone).unix(); //no need * 1000
                let enddate = Moment.tz(bookedinfo.bookedend,defaultinit.timezone).add(addmin, 'minutes').unix(); //no need * 1000                
                setBookedinfo(null);

                const response = await apibase.put('calendar_provider/calendarevents?secret='+defaultinit.secret +'&eventid='+eventid+'&endDate='+enddate)
                .then(res => {
                    getEventApi();
                    onScreenChange('MainBookedScreen')
                })
                .catch(error => {
                    setBookedinfo(bookedinfo);
                    if (error.response)
                    {
                        CustomAlert(""+error.response.data.message+"");
                    }
                    getEventApi();
                });
            }
            else {
                if (title && fromwho)
                {
                    setModalVisible(false);
                    setBookedinfo(null);
                    const addmin = mymin;
                    apibase.defaults.headers.common['Authorization'] = 'Bearer '+defaultinit.api_token;
                    const now = Moment.tz(new Date(),defaultinit.timezone).format('YYYY-MM-DD HH:mm:ss');
                    const now1 = Moment.tz(new Date(),defaultinit.timezone).format('YYYY-MM-DD HH:mm:00');
                    //event_start,'YYYY-MM-DD HH:mm:ss'
                    const startdate = Moment.tz(now,'YYYY-MM-DD HH:mm:ss',defaultinit.timezone).unix(); //no need * 1000
                    const enddate = Moment.tz(now1,'YYYY-MM-DD HH:mm:ss',defaultinit.timezone).add(addmin, 'minutes').unix(); //no need * 1000 

                //  const startdate = Moment.tz(new Date(),defaultinit.timezone).unix(); //no need * 1000
                //  const enddate = Moment.tz(new Date(),defaultinit.timezone).add(addmin, 'minutes').unix(); //no need * 1000                
        
                    const eventsubmit = { startDate: startdate, endDate: enddate, eventTitle: title, bookedFrom: fromwho};

                    //const response = await apibase.post('calendar_provider/calendarevents?secret='+defaultinit.secret +'&startDate='+startdate+'&endDate='+enddate)
                    const response = await apibase.post('calendar_provider/calendarevents?secret='+defaultinit.secret, eventsubmit)
                    .then(res => {
                        getEventApi();
                        onScreenChange('MainBookedScreen')                
                    })
                    .catch(error => {
                        setBookedinfo(bookedinfo);
                        if (error.response)
                        {
                            CustomAlert(""+error.response.data.message+"");
                        }
                        getEventApi();
                    });

                }
                else
                {
                    alert(language.alert_event_title_name)
                }
            }
        }  
    };
    
    const getMinutes = (min) =>{
        if (eventid && eventid !="")
        {
            return (   
                
                <TouchableOpacity activeOpacity={0.95} style={[styles.buttonMinExtend,{backgroundColor: defaultinit.bg_main_footer}]} onPress = {() => showTitle(min)}>
                    <MaterialCommunityIcons name="plus-thick" size={24} color={defaultinit.textcolor_main} />
                    <Text style={[styles.textExtend,{color:defaultinit.textcolor_main}]}>
                    {min} {language.minutes}</Text>
                </TouchableOpacity>
            );
            /*
            return (            
                <TouchableOpacity activeOpacity={0.95} style={styles.buttonMin} onPress = {() => showTitle(min)}>
                <Text style={styles.textExtend}>
                    Extend {'\n'}{min} Minutes</Text>
                </TouchableOpacity>
            );
            */
        }
        else
        {
            return (            
                <TouchableOpacity activeOpacity={0.95} style={[styles.buttonMin, {backgroundColor: defaultinit.bg_main_footer}]} onPress = {() => showTitle(min)}>
                <Text style={[styles.text,{color: defaultinit.textcolor_main}]}>
                    {min} {language.minutes}</Text>
                </TouchableOpacity>
            );
        }
    };

    const getEventTitle = () =>{
        if (eventid && eventid !="")
        {
            return null;
        }
        else
        {
            return (
                <EventTitle 
                    direction={direction} 
                    titlesubmit={fastEvent} 
                    modalVisible={modalVisible} 
                    setModalVisible={setModalVisible} 
                    title={title} 
                    onTitleChange={(newTitle) => setTitle(newTitle)}
                    fromwho={fromwho} 
                    onFromwhoChange={(newFromwho) => setFromwho(newFromwho)}                    
                ></EventTitle>
            );
        }
    };

    const getCustomButton = () =>{
        if (eventid && eventid !="")
        {
            return null;
        }
        else
        {
            return (
            <TouchableOpacity activeOpacity={0.95} style={[styles.buttonMinCustom,{backgroundColor: defaultinit.bg_main_footer }]} onPress = { ()=> onScreenChange('ScheduleMeetingScreen') }>
                <MaterialCommunityIcons name="plus-thick" size={24} color={defaultinit.textcolor_main} />
            </TouchableOpacity>        
            );
        }
    };

    const getPinShow = () => {
        if (defaultinit.pin_required == "1")
        {
            if (eventid && eventid !="")
            {
                return (
                    <ShowPin
                        direction={direction}
                        modalpinVisible={modalpinVisible}
                        setModalPinVisible={setModalPinVisible}
                        fastEvent={fastEvent}
                        from={1}
                    ></ShowPin>
                );
            }
            else
            {
                return (
                    <ShowPin
                        direction={direction}
                        modalpinVisible={modalpinVisible}
                        setModalPinVisible={setModalPinVisible}
                        fastEvent={fastEvent}
                        setModalVisible={setModalVisible}
                        from={0}
                    ></ShowPin>
                );
            }
        }
        else
        {
            return null;
        }
    };
    
    useEffect(() => {

            if (bookedinfo["isbooked"])
            {
                //if (dateStateNext)
                if (dateStateCurrentNext["nstart"])
                {
                    let intervalId = setInterval(() => {
                        let utime = Moment.tz(new Date(),defaultinit.timezone).unix()*1000;
                        let diffb = Moment.duration(Moment.tz(new Date(dateStateCurrentNext["nstart"]),defaultinit.timezone).diff(Moment.tz(new Date(dateStateCurrentNext["cend"]),defaultinit.timezone))); 
                        setTotalMin( parseInt(diffb.asMinutes()) );
                    }, 1000);
                    return(() => {
                        clearInterval(intervalId)
                    })
                }
                else
                {
                    setTotalMin(60);
                }
            }
            else
            {
                if (dateStateCurrentNext["cstart"])
                {
                    let intervalId = setInterval(() => {
                        let utime = Moment.tz(new Date(),defaultinit.timezone).unix()*1000;
                        let diffb = Moment.duration(Moment.tz(new Date(dateStateCurrentNext["cstart"]),defaultinit.timezone).diff(Moment.tz(new Date(utime),defaultinit.timezone))); 
                        setTotalMin( parseInt(diffb.asMinutes()) )
                    }, 1000);

                    return(() => {
                        clearInterval(intervalId)
                    })
                }
                else
                {
                    setTotalMin(60);
                }
            }
        
    }, [dateStateCurrentNext]);    
    

    if (!totalmin)
    {
        return null;
      /*  
        return   <>
        <View style={{ flexDirection: 'row'}}>
        <ActivityIndicator size= {30} color="#ffffff" />
      </View>
      </>
      */
      
    }
    if (totalmin >=60)
    {
        return <View style={{ flexDirection: 'row'}} >
            {
                getPinShow()
            }
            {
                getEventTitle()
            }
            {
                getMinutes(15)
            }
            {
                getMinutes(30)
            }
            {
                getMinutes(60)
            }
            {
                getCustomButton()
            }        
    </View>
    }
    else if (totalmin >=30 )
    {
        return <View style={{ flexDirection: 'row'}} >
            {
                getPinShow()
            }
            {
                getEventTitle()
            }
            {
                getMinutes(15)
            }
            {
                getMinutes(30)
            }
            {
                getMinutes(totalmin)
            }
            {
                getCustomButton()
            }
    </View>        
    }
    else if (totalmin >= 15 )
    {
        return <View style={{ flexDirection: 'row'}} >
            {
                getPinShow()
            }
            {
                getEventTitle()
            }
            {
                getMinutes(15)
            }
            {
                getMinutes(totalmin)
            }
            {
                getCustomButton()
            }
    </View>   
    }
    else if (totalmin >= 5 )
    {
        return <View style={{ flexDirection: 'row'}} >
            {
                getPinShow()
            }
            {
                getEventTitle()
            }
            {
                getMinutes(totalmin)
            }
            {
                getCustomButton()
            }
    </View>   
    }    
    else
    {
    //    alert(totalmin)
        return null;
    }
}


const styles = StyleSheet.create({
    buttonMinExtend: {
          flexDirection: 'row',
        flex: 45,
          height: 60, 
    //      backgroundColor: '#203864',
          alignItems: 'center',
          justifyContent: 'center',
          marginRight:2,
          paddingLeft: 10
     //     marginTop: 5,
      //    elevation:3,
            //   borderRadius: 50,
        },

    buttonMin: {
        //  flexDirection: 'row',
        flex: 45,
          height: 60, 
    //      backgroundColor: '#203864',
          alignItems: 'center',
          justifyContent: 'center',
          marginRight:2,
     //     marginTop: 5,
      //    elevation:3,
            //   borderRadius: 50,
        },
        buttonMinCustom: {
            //  flexDirection: 'row',
            flex: 45,
              height: 60, 
        //      backgroundColor: '#203864',
              alignItems: 'center',
              justifyContent: 'center',
         //     marginTop: 5,
          //    elevation:3,
                //   borderRadius: 50,
            },

    /*
    buttonMin:{
        flex: 33,
        height: 60, 
        backgroundColor: 'rgba(52, 52, 52, 0.7)',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 5,
        elevation:3,
        borderRadius: 30,
    },
    */
    text:{
        fontSize: 23,
    //    color: '#fff',
        margin: 15
    },
    textExtend:{
        fontSize: 23,
    //    color: '#fff',
        marginBottom: 15 ,
        marginTop: 15,
        marginRight: 15,
        alignSelf: 'center',
        //flex: 1
    },
    /*
    textExtend:{
        fontSize: 16,
        color: '#fff',
        margin: 2,
        textAlign:'center'
    },
    */
});

export default FastBookButton;