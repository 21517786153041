import axios from 'axios';

const myaxios = axios.create({
    baseURL: 'https://cloud.infinityroom.pro/api/',
});
/*
if (defaultinit.api_token)
{
    myaxios.defaults.headers.common['Authorization'] = 'Bearer '+defaultinit.api_token;
}
*/

export default myaxios;
/*
export default axios.create({
    baseURL: 'https://cloud.infinityroom.pro/api/',
    Authorization: 'Bearer ODH4b4WybAGcQhwykKmGX8Vf7cjjm7Do8FR0TuKnKcx2XJ2aNFkRt1KqME6M'
}); */