import React from 'react';
import {
  View,
  Text,
  Image,
  StyleSheet,
  SafeAreaView,
  ImageBackground,
} from 'react-native';
const NoPermissionScreen = () => {
  return (
    <SafeAreaView style={styles.container}>
      <ImageBackground
        style={{flex: 1}}
        source={require('../../assets/nopermission-bg.jpg')}
        >
        <View style={styles.container}>
          <Text style={styles.title}>
            Unauthorized
          </Text>
        </View>
      </ImageBackground>
    </SafeAreaView>
  );
};
export default NoPermissionScreen;
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    //alignItems: 'center',
  },
  title: {
    fontSize: 25,
    padding: 15,
    color: 'white',
    fontWeight: 'bold',    
    textAlign: 'center',    
  },
});

