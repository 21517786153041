import {React,useContext } from 'react';
import { View, StyleSheet, Text, TouchableOpacity, Image, Button } from 'react-native';
import NetworkContext from '../context/NetworkContext';
import { MaterialIcons } from '@expo/vector-icons';
import CircleCountdown from './CircleCountdown';

const HideShowAction = ({from,direction,hideright,onHideRightChange}) => {
    const {bookedinfo,defaultinit,language} = useContext(NetworkContext);
    return <View style={styles.container}>
        <View style={[styles.top]}>
        <Text style={[styles.subject,{color:defaultinit.textcolor_main}]}>{defaultinit.display_name}</Text>
        </View>
        {
            (from == "1")?<View style={[styles.middle]}><Text style={[styles.subject,{color:defaultinit.textcolor_main}]}>{bookedinfo["eventtitle"]}</Text><Text style={[styles.eventorg,{color:defaultinit.textcolor_main}]}>{bookedinfo["eventorganizer"]}</Text><CircleCountdown></CircleCountdown></View>:<View style={[styles.middle]}><Text style={[styles.subject,{color:defaultinit.textcolor_main}]}>{language.book_now}</Text></View>
        }        
        {
            (direction == "column")?null:
                (hideright == false)?<MaterialIcons name="keyboard-arrow-right" size={48} color={defaultinit.textcolor_main} style={styles.arrorStyle} onPress = { ()=> {onHideRightChange(true) } } />:
                <MaterialIcons name="keyboard-arrow-left" size={48} color={defaultinit.textcolor_main} style={styles.arrorStyle}  onPress = { ()=> {onHideRightChange(false)  } } />
        //    (hideright == false)?<MaterialIcons name="keyboard-arrow-right" size={48} color="white" style={styles.arrorStyle} onPress = { ()=> {fadeOut(); onHideRightChange(true) } } />:
        //    <MaterialIcons name="keyboard-arrow-left" size={48} color="white" style={styles.arrorStyle}  onPress = { ()=> {fadeIn(); onHideRightChange(false)  } } />            
        }  
    </View>
}

const styles = StyleSheet.create({
    arrorStyle: {
        position: 'absolute',
        right: 0,
        paddingRight:0,
    },
    subject:{
        fontSize: 46,
    //    padding: 4,
        textTransform:'uppercase',
    //    color: 'white',
        fontWeight: 'bold',    
        //marginBottom: 55,
        alignSelf:'center'
    },
    eventorg: {
        fontSize: 22,
        padding: 4,
    //    color: '#b9babc',
      //  fontWeight: 'bold',    
      //  textAlign: 'center',
      alignSelf:'center'    
    },
    container: {
        flex: 1,
        justifyContent: 'center',
      //  alignItems: 'center',
      },
      top:{
        flex: 30,
    //    backgroundColor: '#203864',
        justifyContent: 'center',
    //    borderBottomWidth: 1,
        //   borderTopWidth:1,
    //       borderColor: '#ccc',
        //   borderStyle: 'dotted',
      },
      middle:{
        flex: 70,
    //    backgroundColor: '#203864',
        justifyContent: 'center'
      },
})

export default HideShowAction;