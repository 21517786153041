import {React, useState, useEffect,useContext } from 'react';
import { View, Text, FlatList , StyleSheet,TouchableOpacity} from 'react-native';
import Moment from 'moment-timezone';
import NetworkContext from '../context/NetworkContext';


const TimePicker = ({dataSource,mystart, onMyStartChange, myend, onMyEndChange}) => {
  const mya = dataSource.eventselected;
  const [myb, setMyb] = useState(11);
  const [aselect, setAselect] = useState([]);
  const {defaultinit,language} = useContext(NetworkContext);    

  const setselectTime = (varindex,val) => {
    let tmpa = "";
    let tmpb = "";
    let x =0;
    let newvv = "";

    let mysetarray = [];
    let myutime = dataSource.datestring+" "+val+":00";
    let utime = Moment.tz(myutime,'YYYY-MM-DD HH:mm:ss',defaultinit.timezone).unix()*1000;
    let displayhhmm = (defaultinit.timeformat == "12")?Moment.tz(myutime,'YYYY-MM-DD HH:mm:ss',defaultinit.timezone).format('h:mmA'):Moment.tz(myutime,'YYYY-MM-DD HH:mm:ss',defaultinit.timezone).format('HH:mm');
    let myhm = 720;
    let mynumber = 0;
    let displaya = "";
    tmpa = { index: varindex, hhmm: val, displayhhmm: displayhhmm};
    let finda = mya.find(el => (Moment.tz(el.start,defaultinit.timezone).unix()*1000) >= utime);

    if (finda)
    {
      let diffa = Moment.duration(Moment.tz(new Date(finda.start),defaultinit.timezone).diff(Moment.tz(new Date(utime),defaultinit.timezone)));
      myhm = diffa.asMinutes();
    }

    for (x=0; x <=11; x++)
    {
      switch (true) {
        case ( x <4) :
          mynumber = (x) * 15;
          newvv = Moment.tz(myutime,'YYYY-MM-DD HH:mm:ss',defaultinit.timezone).add(mynumber, 'minutes');
        break;

        case (x < 8):
          mynumber = 4 * 15 +  (x-4) * 30;
          newvv = Moment.tz(myutime,'YYYY-MM-DD HH:mm:ss',defaultinit.timezone).add(mynumber, 'minutes');
        break;

        case (x < 9):
          mynumber = 4 * 15 +  (x-4) * 30 + 30;
          newvv = Moment.tz(myutime,'YYYY-MM-DD HH:mm:ss',defaultinit.timezone).add(mynumber, 'minutes');
        
        break;

        default:
          mynumber = (4 * 15) +  (4 * 30) + (60) +  ((x-9) * 240);
          newvv = Moment.tz(myutime,'YYYY-MM-DD HH:mm:ss',defaultinit.timezone).add(mynumber , 'minutes');
      }
      let diffb = Moment.duration(Moment.tz(new Date(newvv.unix() *1000),defaultinit.timezone).diff(Moment.tz(new Date(utime),defaultinit.timezone)));
    
      if (diffb.asMinutes() >= myhm)
      {
        setMyb(x);
        newvv = Moment.tz(myutime,'YYYY-MM-DD HH:mm:ss',defaultinit.timezone).add(myhm , 'minutes');

        displaya = ( (Math.floor(myhm / 60))?  Math.floor((myhm) / 60) +'H': '') + ' '+ ( (Math.floor((myhm) % 60))?  Math.floor((myhm) % 60) +'MIN': '');
        mysetarray[varindex+x] = {v: displaya, hhmm: newvv.format('HH:mm'), displayhhmm: (defaultinit.timeformat == "12")?newvv.format('h:mmA'):newvv.format('HH:mm')};
        //mysetarray[varindex+x] = {v: displaya, hhmm: newvv.format('HH:mm')};
        break;                  
      }
      else{
        displaya = ( (Math.floor(mynumber / 60))?  Math.floor((mynumber) / 60) +'H': '') + ' '+ ( (Math.floor((mynumber) % 60))?  Math.floor((mynumber) % 60) +'MIN': '');

        mysetarray[varindex+x] = {v: displaya, hhmm: newvv.format('HH:mm'), displayhhmm: (defaultinit.timeformat == "12")?newvv.format('h:mmA'):newvv.format('HH:mm')};
        //mysetarray[varindex+x] = {v: displaya, hhmm: newvv.format('HH:mm')};
      }
    }
    setAselect(mysetarray);
    onMyStartChange(tmpa);
  };

  const selectTime = (varindex,val) =>{        
    let tmpa = "";
    let tmpb = "";
    let x =0;
    let newvv = "";


    if (mystart)
    {
        if ( val === mystart.hhmm && !myend )
        {
          setMyb(11);
            onMyStartChange(null);
            setAselect([]);
            //onMyEndChange(null)
        }
        else if ( !myend && (val < mystart.hhmm ))
        {
          setselectTime(varindex,val);
        }

        if (myend && (varindex === myend.index))
        {
            onMyEndChange(null)
        }
        else if (val > mystart.hhmm)
        {
          let tmpv = varindex - mystart.index;
          let newv = aselect[varindex].hhmm;
          let displaynewv = aselect[varindex].displayhhmm;
          tmpb = { index: varindex, hhmm: newv, displayhhmm: displaynewv};
          //onMyEndChange(newv)
          onMyEndChange(tmpb);                
        }
    }
    else{
      setselectTime(varindex,val);
    }        
  };

  const renderItem = ({ item, index }) => {


    if (!mystart && !myend && item.status == 'empty') {
      return <View style={[styles.item, styles.itemInvisible]} />;
    }
    else if (!(aselect[index]) && item.status == 'occupied')
    {
      return <View style={[styles.item, styles.itemOccupied,{backgroundColor:defaultinit.bg_main_center}]}><Text style={[styles.itemText,{color:defaultinit.textcolor_main}]}> X </Text></View>;
    }
    else if (item.src >= '24:00'){
      if (mystart && (index <= mystart.index+myb && index >=mystart.index))
      {}
      else
      {
        return <View style={[styles.item, styles.itemInvisible]} />;
      }
    }
    return (
      <View style= {mystart && ( (mystart.index == index) || (index >= mystart.index && index <= mystart.index+myb)  )? 
      (mystart && mystart.hhmm) == item.src ?styles.itemSelectWhite:
      (myend && (mystart.index < index  && index <= myend.index))?styles.itemSelectWhite:[styles.itemSelect,{backgroundColor:defaultinit.bg_main_center}] :
      [styles.item,{backgroundColor:defaultinit.bg_main_center}] } >               
        <TouchableOpacity
          onPress={
          ((mystart && ( (mystart.index >= index) || (index >= mystart.index && index <= mystart.index+myb)) ) || !mystart) ? 
          ()=>{selectTime(index, item.src)} 
          :null
          } style={styles.touchstyle}>
          <Text style={ ((mystart && mystart.hhmm == item.src) || (myend && (mystart.index < index  && index <= myend.index)) ) ?styles.itemTextStart:[styles.itemText,{color:defaultinit.textcolor_main}]}> 
              {                         
              (mystart && ( (mystart.index == index) || (index >= mystart.index && index <= mystart.index+myb)  ))?
              (aselect[index]? 
                ((mystart && mystart.hhmm == item.src) || (myend && (mystart.index < index  && index <= myend.index)) )? aselect[index].displayhhmm:
                // aselect[index].hhmm + '\n'+aselect[index].v : '')
                <>{aselect[index].displayhhmm} {'\n'} <View style={[styles.textBorderOffStyle,{backgroundColor:defaultinit.bg_main_header_footer, borderColor:'defaultinit.bg_main_header_footer'}]}><Text style={[styles.textSmallOffStyle,{color:defaultinit.textcolor_main}]}>{aselect[index].v} </Text></View></> : '')
            //   (index - mystart.index) <= 4 ? Moment(mystart.hhmm, "HH:mm").add((index - mystart.index) * 15, 'minutes').format('HH:mm') + '\n'+ ( (Math.floor((index - mystart.index)* 15 / 60))?  Math.floor((index - mystart.index)* 15 / 60) +'H': '') + ''+ ( (Math.floor((index - mystart.index)* 15 % 60))?  Math.floor((index - mystart.index)* 15 % 60) +'MIN': '')  : 
            //   (index - mystart.index) <= 8 ? Moment(mystart.hhmm, "HH:mm").add(4 * 15 +  ((index - mystart.index)-4) * 30, 'minutes').format('HH:mm') + '\n'+ ( (Math.floor( (4 * 15 +  ((index - mystart.index)-4) * 30 )/ 60))?  Math.floor( (4 * 15 +  ((index - mystart.index)-4) * 30) / 60) +'H': '') + ''+ ( (Math.floor((4 * 15 +  ((index - mystart.index)-4) * 30) % 60))?  Math.floor((4 * 15 +  ((index - mystart.index)-4) * 30) % 60) +'MIN': '') : 
            //   (index - mystart.index) <= 9 ? Moment(mystart.hhmm, "HH:mm").add(4 * 15 +  ((index - mystart.index)-4) * 30 + 30, 'minutes').format('HH:mm') + '\n'+ ( (Math.floor((4 * 15 +  ((index - mystart.index)-4) * 30 + 30) / 60))?  Math.floor((4 * 15 +  ((index - mystart.index)-4) * 30 + 30) / 60) +'H': '') + ''+ ( (Math.floor((4 * 15 +  ((index - mystart.index)-4) * 30 + 30) % 60))?  Math.floor((4 * 15 +  ((index - mystart.index)-4) * 30 + 30) % 60) +'MIN': '') :  
            //   Moment(mystart.hhmm, "HH:mm").add((4 * 15) +  (4 * 30) + (60) +  (((index - mystart.index)-9) * 240) , 'minutes').format('HH:mm') + '\n'+ ( (Math.floor(((4 * 15) +  (4 * 30) + (60) +  (((index - mystart.index)-9) * 240)) / 60))?  Math.floor(((4 * 15) +  (4 * 30) + (60) +  (((index - mystart.index)-9) * 240)) / 60) +'H': '') + ''+ ( (Math.floor(((4 * 15) +  (4 * 30) + (60) +  (((index - mystart.index)-9) * 240)) % 60))?  Math.floor(((4 * 15) +  (4 * 30) + (60) +  (((index - mystart.index)-9) * 240)) % 60) +'MIN': '')
              :item.srcdisplay
            }
          </Text>                      
          { mystart && mystart.hhmm == item.src ? <View style={styles.textBorderStyle}><Text style={styles.textSmallStyle}>{language.start}</Text></View> : null }
          { myend && myend.index == index ? <View style={styles.textBorderStyle}><Text style={styles.textSmallStyle}>{language.end}</Text></View> : null }   
          { /*myend == item.src ? <Button title="End"></Button> : null */}      
        </TouchableOpacity>
        </View>
    )
  };

return <View>
<View style={[styles.containerStyle,{backgroundColor:defaultinit.bg_main_header_footer}]}>
    <Text style={[styles.textBoldStyle,{color:defaultinit.textcolor_main}]}>
    { mystart ?""+language.start+" : "+ mystart.displayhhmm: language.no_time_selected}{ myend ? "\n" +language.end+' : '+ myend.displayhhmm: null}
    </Text>
    <Text style={[styles.textBoldStyle,{color:defaultinit.textcolor_main}]}>
      { Moment.tz(dataSource.datestring,'YYYY-MM-DD',defaultinit.timezone).format('Do MMMM YYYY') }      
    </Text>
    </View>
    <View style={[styles.containerTimeStyle,{backgroundColor:defaultinit.bg_main_header_footer}]}>
    <FlatList
        data={dataSource.data}
        renderItem={renderItem}
        //Setting the number of column
        numColumns={6}
        keyExtractor={(item, index) => index}
      />
    </View>
  </View>
};

const styles = StyleSheet.create({
  containerStyle: {
    borderBottomWidth: 0,
    fontSize:16,
    color: '#fff',
    //padding: 8,
    //paddingLeft: 30,
    height:60,
  //  backgroundColor: '#203864',
    justifyContent: 'space-between',
    flexDirection: 'row',
    //borderColor: '#ddd',
    position: 'relative',
    alignItems: 'center',
    //justifyContent: 'center',
  },
  containerTimeStyle: {
    borderBottomWidth: 0,
    fontSize:16,
   // color: '#fff',
    //padding: 8,
    //paddingLeft: 30,
  //  backgroundColor: '#203864',
    //justifyContent: 'space-between',
    flexDirection: 'row',
    //borderColor: '#ddd',
    position: 'relative',
    alignItems: 'center',
    //justifyContent: 'center',
  },
  textSmallStyle:{
    fontSize: 12, 
    color: '#869ec9',
    alignSelf: 'center',
    padding: 1,
  },
  textBoldStyle:{
    fontSize: 16, 
  //  color: '#fff',
    alignSelf: 'center',
    padding: 4,
    fontWeight: 'bold'
  },
  textBorderStyle:{
    //backgroundColor: "black",
    borderColor: '#869ec9',
    borderWidth: 1,
    borderRadius:6
  },
  textSmallOffStyle:{
    fontSize: 12, 
  //  color: '#fff',
    alignSelf: 'center',
    padding: 1,
  },
  textBorderOffStyle:{
  //  backgroundColor: "#2b54a0",
  //  borderColor: '#2b54a0',
    borderWidth: 1,
    borderRadius:6
  },

  touchstyle: {
    width:'100%', 
    height:'100%',
    alignItems: 'center', 
    justifyContent:'center' 
  },
  item: {
  //  backgroundColor: '#5274b1',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    margin: 1,
    height: 80, // approximate a square
  },
  itemSelect: {
  //  backgroundColor: '#4a6dad',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    margin: 1,
    height: 80, // approximate a square
  },
  itemSelectWhite: {
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    margin: 1,
    height: 80, // approximate a square
  },
  itemInvisible: {
    backgroundColor: 'transparent',
  //  backgroundColor:'red'
  },
  itemOccupied: {
    //backgroundColor: 'transparent',

 //   backgroundColor:'rgba(82,116,177,0.8)'
  },
  itemText: {
  //  color: '#fff',
    textAlign: 'center',
  },
  itemTextStart:{
    color: '#869ec9',
  },
});

export default TimePicker;
